import {Model} from './model';
import moment from 'moment';
import {isLower, assert, extendModel} from './utils';
import {RadioControlMaker, TextControl} from './Pages';
import _ from 'lodash';
import {useState, useEffect} from 'react';

const COINGECKO_API_BASE = 'https://pro-api.coingecko.com/api/v3/';
const API_KEY = 'CG-7PJ1kbZseLG4echGmkr9hdJz';

export const PriceFetcher = Model.register('price-fetcher', class PriceFetcher extends Model {
  static properties = {}

  async fetch() {
    throw new Error("Please override this method");
  }

  static formControl() {
    return (label, value, onChange) => {
      return RadioControlMaker()(`PriceFetcher Type`, value.ticker, ticker => onChange(extendModel(value, {ticker})));
    };
  }

  become(PriceFetcherType) {
    return new PriceFetcherType(this.serialize())
  }
});

function PriceFetcherRenderer(props) {
  const {fetch, ticker} = props;
  const [isFetching, setIsFetching] = useState(false);
  const [fetched, setFetched] = useState(undefined);

  useEffect(() => {
    async function fetchPrice() {
      setIsFetching(true);
      try {
        console.log('here');
        setFetched(await fetch());
      } catch (err) {
        setFetched(err);
      } finally {
        setIsFetching(false);
      }
    }

    if (fetched === undefined && !isFetching && !_.isEmpty(ticker)) {
      fetchPrice();
    }
  }, [fetched, fetch, isFetching, ticker]);

  useEffect(() => {
    setFetched(undefined);
  }, [ticker]);

  if (_.isEmpty(ticker)) {
    return <div>N/A</div>
  } else if (fetched === undefined) {
    return <div>Loading...</div>
  } else if (fetched instanceof Error) {
    return <div style={{color: 'red'}}>{fetched.message}</div>
  } else {
    return <div>{fetched}</div>
  }
}

const currentDate = () => {
  return moment().format('l');
}

const [cgFetchPrefix, cgFetchHistoryPrefix] = ['__cgFetchCache__', 'cgFetchHistoryCache'];
const cgCacheHash = (ticker, currencyName, date) => {
  return `${ticker}/${currencyName}/${date}`;
}

window.blowFetchPriceCaches = () => {
  console.log('Blowing Fetch Price caches!');
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key.startsWith(cgFetchPrefix) || key.startsWith(cgFetchHistoryPrefix)) {
      localStorage.removeItem(key);
    }
  }
};

export const CoinGeckoPriceFetcher = Model.register('coin-gecko-fetcher', class CoinGeckoPriceFetcher extends PriceFetcher {
  static displayName = 'CoinGecko Fetcher';
  static properties = {
    ticker: String,
  }

  clone() {
    const clone = super.clone();
    clone.fetchCache = this.fetchCache;
    clone.fetchHistoryCache = this.fetchHistoryCache;
    return clone;
  }


  async fetch(currency = {name: 'usd'}) {
    const currencyName = currency.name.toLowerCase();
    assert(() => isLower(this.ticker));

    const cached = localStorage.getItem(cgFetchPrefix + cgCacheHash(this.ticker, currencyName, currentDate()));
    if (cached !== null) {
      return JSON.parse(cached);//.market_data.current_price[currencyName.toLowerCase()];
    }

    console.log(`Fetching ${this.ticker}`);
    const ret = await fetch(`${COINGECKO_API_BASE}/coins/${this.ticker}?x_cg_pro_api_key=${API_KEY}`)
    if (ret.ok && ret.status === 200) {
      const retVal = (await ret.json()).market_data.current_price[currencyName];
      try {
        localStorage.setItem(cgFetchPrefix + cgCacheHash(this.ticker, currencyName, currentDate()), JSON.stringify(retVal));
      } catch (err) {
        window.blowFetchPriceCaches();
      }
      return retVal;
    } else {
      return new Error(`Fetch price request failed for ${this.ticker}`);
    }
  }

  async fetchHistory(currency = {name: 'usd'}) {
    const currencyName = currency.name.toLowerCase();
    assert(() => isLower(this.ticker));

    const cached = localStorage.getItem(cgFetchHistoryPrefix + cgCacheHash(this.ticker, currencyName, currentDate()));
    if (cached !== null) {
      return JSON.parse(cached).prices;
    }

    console.log(`Fetching history ${this.ticker}`);

    const url = new URL(`coins/${this.ticker}/market_chart/range`, COINGECKO_API_BASE);
    const params = {
      x_cg_pro_api_key: API_KEY,
      vs_currency: currencyName,
      from: moment().subtract(6, 'months').unix(),
      to: moment().unix(),
    };
    _.forEach(params, (val, key) => url.searchParams.append(key, val));
    const ret = await fetch(url.toString());
    if (ret.ok && ret.status === 200) {
      const retJson = await ret.json();
      try {
        localStorage.setItem(cgFetchHistoryPrefix + cgCacheHash(this.ticker, currencyName, currentDate()), JSON.stringify(retJson));
      } catch (err) {
        window.blowFetchPriceCaches();
      }
      return retJson.prices;
    } else {
      return new Error(`Fetch history request failed for ${this.ticker}`);
    }
  }

  static formControl() {
    return (label, value, onChange) => {
      return TextControl(`${label} ticker`, value.ticker, ticker => onChange(extendModel(value, {ticker})));
    };
  }

  render() {
    return <PriceFetcherRenderer ticker={this.ticker} fetch={this.fetch.bind(this)} />
  }
});

export const ethPriceFetcher = new CoinGeckoPriceFetcher({ticker: 'ethereum'});

export const CodePriceFetcher = Model.register('code-price-fetcher', class CodePriceFetcher extends PriceFetcher {
  static displayName = 'Code Price Fetcher';
  static properties = {
    code: String,
  }

  async fetch(currency = {name: 'usd'}) {
    const ethCurrentPrice = await ethPriceFetcher.fetch();
    try {
      // eslint-disable-next-line no-new-func
      return new Function('currency, ethPrice', this.code)(currency, ethCurrentPrice);
    } catch(err) {
      return err;
    }
  }

  async fetchHistory(currency = {name: 'usd'}) {
    const ethPriceHistory = await ethPriceFetcher.fetchHistory();
    return ethPriceHistory.map(([timestamp, price]) => {
      try {
        // eslint-disable-next-line no-new-func
        return [timestamp, new Function('currency, ethPrice', this.code)(currency, price)];
      } catch(err) {
        return [timestamp, err];
      }
    });
  }
});
