import React, { Component } from 'react';
import { Link, useLocation } from "react-router-dom";
import _ from 'lodash';


class InnerHeader extends Component {
  render () {
    return (
      <div className="horizontal-menu">
        <nav className="navbar top-navbar default-layout-navbar col-lg-12 col-12 p-0 d-flex flex-row">
          <div className="container" style={{height: 80, paddingTop: 20, paddingBottom: 20, display: 'flex', justifyContent: 'space-betwen'}}>
            <div>
              <Link to="/" style={{fontSize: '1.5rem', color: 'white', width: 200}}>
                <img src={require('../../assets/images/tobi-mask.png')} alt="dfk" style={{marginRight: 10, height: 30}} />
                Tobi DeFi Tracker
              </Link>
            </div>

            <img src={require('../../assets/images/kurama.png')} alt="kurama" style={{marginLeft: 10, height: 50}} />
          </div>
        </nav>

        <nav className="bottom-navbar">
          <div className="container">
            <ul className="nav page-navigation" style={{justifyContent: 'left'}}>
              <li className={ this.isPathActive('/ledger') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
                <Link className="nav-link" to="/ledger">
                  <i className="mdi mdi-speedometer menu-icon"></i>
                  <span className="menu-title">Main</span>
                </Link>
              </li>
              <li className={ this.isPathActive('/equity') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
                <Link className="nav-link" to="/equity">
                  <i className="mdi mdi-file-restore menu-icon"></i>
                  <span className="menu-title">Equity</span>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }

  toggleBottomMenu() {
    document.querySelector('.bottom-navbar').classList.toggle('header-toggled');
  }

  toggleRightSidebar() {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }

  componentDidMount() {
    // Horizontal menu fixed when scrolling
    let navbar = document.querySelector('.horizontal-menu');
    let body = document.querySelector('body');
    if ( navbar ) {
      window.addEventListener('scroll', function() {
        if (window.scrollY >= 70) {
          navbar.classList.add('fixed-on-scroll');
          body.classList.add('horizontal-menu-fixed-on-scroll');
         }
        else {
          navbar.classList.remove('fixed-on-scroll');
          body.classList.remove('horizontal-menu-fixed-on-scroll');
         }
      });
    }

    // Horizontal menu navigation in mobile menu on click
    let navItemClicked = document.querySelectorAll('.horizontal-menu .page-navigation >.nav-item');
    navItemClicked.forEach(function (el) {
      el.addEventListener('click', function () {
        var result = [],
          node = this.parentNode.firstChild;
        while (node) {
          if (node !== this)
            result.push(node);
          node = node.nextElementSibling || node.nextSibling;
        }
        result.forEach( (el) => el.classList.remove('show-submenu') )
        this.classList.toggle('show-submenu');
      });
    })
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

}

function Header(props) {
  let loc = useLocation();
  const propsWithLocation = _.extend({}, props, {location: loc});
  return <InnerHeader {...propsWithLocation} />
}

export default Header;
