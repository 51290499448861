import React, { useEffect, useState} from 'react';
import {useParams} from 'react-router';
import moment from 'moment';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Dropdown, Form } from 'react-bootstrap';
import _ from 'lodash';
import DatePicker from "react-datepicker";
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import defaultWorldStateJson from './default-world-state.json';

import {formatPercentage, extendModel, assert, formatFiatValue, LinkWithAddr, InfoTooltip, addSign, formatTokenValue, Copiable, formatAddress, truncateLongAddressCopiable, truncateLongString } from './utils';

// import brace from "brace";

import AceEditor from "react-ace";

// Import a Mode (language)
import "brace/mode/json";
import 'brace/mode/javascript';

// Import a Theme (okadia, github, xcode etc)
import "brace/theme/github";
import "brace/theme/monokai";

import {LedgerAccount, LedgerEntry, LedgerTransaction, Rule, Contract, WorldState} from './App';
import {useTransactionsForAddress } from './TransactionsViewer';

/* global BigInt */

const filterColor = '#474747';

export function ContractManager(props) {
  const [contractIndexBeingEdited, setContractIndexBeingEdited] = useState(-1);
  const resetContractState = () => {
    setContractIndexBeingEdited(-1);
    setEditableContract(new Contract());
    setEditableMetadata('');
  };

  const [editableContract, setEditableContract] = useState(new Contract());
  const [editableMetadata, setEditableMetadata] = useState('');
  const setEditableContractFields = (fieldObj) => {
    return setEditableContract(_.extend(editableContract.clone(), fieldObj));
  };

  const addNewContract = (e) => {
    e.preventDefault();

    try {
      const clone = editableContract.clone();
      clone.metadata = _.isEmpty(editableMetadata) ? {} : JSON.parse(editableMetadata);
      props.worldState.addContract(clone);
      props.handleSave();
      resetContractState();

    } catch (err) {
      window.alert(`Contract creation failed: ${err.message}`);
    }
  }
  const saveContract = (e) => {
    e.preventDefault();

    try {
      const clone = editableContract.clone();
      clone.metadata = _.isEmpty(editableMetadata) ? {} : JSON.parse(editableMetadata);
      props.worldState.replaceContract(contractIndexBeingEdited, clone);
      props.handleSave();
      resetContractState();

    } catch (err) {
      window.alert(`Contract save failed: ${err.message}`);
    }
  }

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">Contracts</h3>
        <InfoTooltip title={'Contract Manager'}>
          Use this page to add any smart contracts you're interested in tracking. ERC20 and ERC721 contracts represent fungible and non fungible tokens. You can also define Price Fetchers for those, to track their price changes over time in $USD and other currencies.
        </InfoTooltip>
      </div>
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <div onClick={resetContractState} style={{paddingBottom: 30}}>
                <div className="row">
                  <h4 className="card-title">Known Contracts</h4>
                </div>
                <div className="row">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Address</th>
                          <th>Blockchain</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.worldState.contracts.map((contract, i) =>
                          <tr style={contractIndexBeingEdited === i ? {background: filterColor} : {}} key={contract.address}
                            onClick={evt => {
                              setContractIndexBeingEdited(i);
                              setEditableContract(contract);
                              evt.stopPropagation();
                          }}>
                            <td>{contract.name}</td>
                            <td>{contract.address}</td>
                            <td>{contract.blockchain}</td>
                            <td><button onClick={() => {
                              if (window.confirm('Are you sure you wish to delete this item?')) {
                                props.worldState.removeContract(contract);
                                props.handleSave();
                              }
                            }} className="btn btn-danger btn-sm">Delete</button></td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row">
                <h4 className="card-title">Contract</h4>
              </div>
              <div className="row">
                <div className="col-md-6 grid-margin">
                  <div className="form-group">
                    <label>ABI</label>
                    <AceEditor style={{minHeight: '300px'}}
                      mode="json"
                      theme="monokai"
                      name="jsonEditor"
                      editorProps={{ $blockScrolling: true }}
                      placeholder="Contract ABI goes here..."
                      fontSize={14}
                      showPrintMargin={true}
                      showGutter={true}
                      highlightActiveLine={true}
                      height="100%"
                      width="100%"
                      value={editableContract.stringifiedAbi}
                      onChange={val => setEditableContractFields({stringifiedAbi: val})}
                    />
                  </div>
                </div>
                <div className="col-md-6 grid-margin">
                  <form className="forms-sample">
                    <Form.Group>
                      <label htmlFor="exampleInputUsername1">Contract Name</label>
                      <Form.Control type="text" id="exampleInputUsername1" placeholder="Master Gardener"
                         value={editableContract.name} onChange={(e) => setEditableContractFields({name: e.target.value})} />
                    </Form.Group>
                    <Form.Group>
                      <label htmlFor="exampleInputEmail1">Contract address</label>
                      <Form.Control type="text" className="form-control" id="exampleInputEmail1" placeholder="one1mvcxg0r34j0zzgk2qdq76a7sn40en7fy7lytq4"
                         value={editableContract.address} onChange={(e) => setEditableContractFields({address: e.target.value})} />
                    </Form.Group>

                    <Form.Group style={{display: 'flex'}}>
                      <span className="form-check mr-2">
                        <label className="form-check-label" onClick={() => setEditableContractFields({type: 'ERC20'})}>
                          <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios1"
                            checked={editableContract.type === 'ERC20'} readOnly />
                          <i className="input-helper"></i>
                          ERC20
                        </label>
                      </span>
                      <span className="form-check mr-2">
                        <label className="form-check-label" onClick={() => setEditableContractFields({type: 'ERC721'})}>
                          <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios1"
                            checked={editableContract.type === 'ERC721'} readOnly />
                          <i className="input-helper"></i>
                          ERC721
                        </label>
                      </span>
                      <span className="form-check mr-2">
                        <label className="form-check-label" onClick={() => setEditableContractFields({type: 'Other'})}>
                          <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios1"
                            checked={editableContract.type === 'Other'} readOnly />
                          <i className="input-helper"></i>
                          Other
                        </label>
                      </span>
                    </Form.Group>

                    {editableContract.typeRequiresTokenName() &&
                      <Form.Group>
                        <label htmlFor="exampleInputUsername3">Token Name</label>
                        <Form.Control type="text" placeholder="JEWEL"
                          value={editableContract.tokenName} onChange={(e) => setEditableContractFields({tokenName: e.target.value})} />
                      </Form.Group>
                    }

                    <div>
                      <button disabled={contractIndexBeingEdited < 0 || contractIndexBeingEdited >= props.worldState.contracts.length} onClick={saveContract}
                        className="btn btn-primary btn-fw mr-3">
                        Save
                      </button>
                      <button onClick={addNewContract} className="btn btn-primary btn-fw">Add new contract</button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 grid-margin">
                  <div className="form-group">
                    <label>Metadata</label>
                    <AceEditor style={{minHeight: '300px'}}
                      mode="json"
                      theme="monokai"
                      name="jsonEditor"
                      editorProps={{ $blockScrolling: true }}
                      placeholder="JSON Metadata added here can be accessed by rules"
                      fontSize={14}
                      showPrintMargin={true}
                      showGutter={true}
                      highlightActiveLine={true}
                      height="100%"
                      width="100%"
                      value={editableMetadata}
                      onChange={setEditableMetadata}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export function StateEditor(props) {
  const [editableJson, setEditableJson] = useState(JSON.stringify(props.worldState.serialize(), null, 2));
  const saveNewState = () => {
    try {
      const newWorldState = WorldState.deserialize(JSON.parse(editableJson));
      props.setWorldState(newWorldState);
      props.handleSave(newWorldState);
      window.alert("New state saved successfully!");
    } catch (err) {
      window.alert(`Badly formatted WorldState json: ${err.message}`);
    }
  };

  const resetToDefaultState = () => {
    const defaultWorldState = WorldState.deserialize(defaultWorldStateJson);
    props.setWorldState(defaultWorldState);
    props.handleSave(defaultWorldState);
    window.alert("App state successfully reset back to defaults!");
  }

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">App State Editor</h3>
        <InfoTooltip title={'Send your App state to a friend'}>
          <div style={{minWidth: 300}}>
            <div>
              Use this page if you want to send your rules, contracts, and price fetchers to a friend for them to use. Just copy the below JSON code and ask others to import it in their own App State Editor, so they can use the rules and configs you created!
            </div>

            <div className="mt-2">
              A more robust rules marketplace is coming soon! :)
            </div>
          </div>
        </InfoTooltip>
      </div>
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <AceEditor style={{minHeight: '600px'}}
                  mode="json"
                  theme="monokai"
                  name="jsonEditor"
                  editorProps={{ $blockScrolling: true }}
                  placeholder="Contract ABI goes here..."
                  fontSize={14}
                  showPrintMargin={true}
                  showGutter={true}
                  highlightActiveLine={true}
                  height="100%"
                  width="100%"
                  value={editableJson}
                  onChange={setEditableJson}
                />
              </div>
              <div className="row" style={{marginTop: 50}}>
                <button onClick={saveNewState} className="btn btn-primary btn-fw">Import new world state</button>
                <button onClick={resetToDefaultState} className="ml-3 btn btn-primary btn-fw">Reset to default world state</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const buildColumns = (worldState) => {
  return [
    {
      dataField: 'tx',
      text: 'tx.hash',
      sort: true,
      formatter: (cellContent, row) =>
        <LinkWithAddr addr={worldState.defaultAddr} to={`/tx/${cellContent.hash}`}>{truncateLongString(cellContent.hash)}</LinkWithAddr>
    }, {
      dataField: 'timestamp',
      text: 'evt.timestamp',
      sort: true,
    }, {
      dataField: 'methodCall',
      text: 'tx.methodCall',
      sort: true,
      formatter: (cellContent, row) => {
        return (row.tx.methodCall)
          ? <div className="badge badge-pill badge-info">{row.tx.methodCall.name}</div>
          : truncateLongAddressCopiable(row.tx.input);
      }
    }, {
      dataField: 'from',
      text: 'tx.from',
      sort: true,
      formatter: (cellContent, row) => formatAddress(row.tx.from, worldState)
    }, {
      dataField: 'to',
      text: 'tx.to',
      sort: true,
      formatter: (cellContent, row) => formatAddress(row.tx.to, worldState)
    }, {
      dataField: 'name',
      text: 'evt.name',
      sort: true,
    }, {
      dataField: 'contractAddress',
      text: 'evt.contract',
      sort: true,
      formatter: (cellContent, row) => formatAddress(cellContent, worldState),
    }, {
      dataField: 'args',
      text: 'evt.args',
      formatter: (cellContent, row) =>
        <div>
        {_.map(cellContent, (arg, name) =>
          <div key={name}>
            <Copiable textToCopy={arg.value} tooltipText={`Value (click to copy): ${arg.value}`}>
            {`${name}: ${arg.type}`}
            </Copiable>
          </div>
        )}
        </div>
    }, {
      dataField: 'rules',
      text: 'Matched Rules',
      formatter: (cellContent, row) =>
        <div>
        {_.map(cellContent, (rule) =>
          <div key={rule.name} className="badge badge-pill badge-success">{rule.name}</div>
        )}
        </div>
    }, {
      dataField: 'effectOfEvent',
      text: 'Overall Effect',
      formatter: (cellContent, row) => {
        if (cellContent instanceof Error) {
          return <div style={{background: 'purple'}}>{cellContent.message}</div>;
        }
        return (
          <div>
          {_.map(cellContent?.toJson() || {}, (effect, token) =>
            <div key={token}>{addSign(formatTokenValue(effect, token))}</div>
          )}
          </div>
        );
      }
    }, {
      dataField: 'effectOfRule',
      text: 'Simulated Effect',
      formatter: (cellContent, row) => {
        if (cellContent instanceof Error) {
          return <div style={{background: 'purple'}}>{cellContent.message}</div>;
        }
        return (
          <div>
          {_.map((row.filteredByRule === true ? cellContent.toJson() : {}), (effect, token) =>
            <div key={token}>{addSign(formatTokenValue(effect, token))}</div>
          )}
          </div>
        );
      }
    }
  ];
};


export function EventRuleManager(props) {
  const [isLoadingTxs, isLoadingReceipts, transactions] = useTransactionsForAddress(props.worldState.defaultAddr, props.worldState);
  return <EventRuleManagerInternal {...props} isLoadingTxs={isLoadingTxs} isLoadingReceipts={isLoadingReceipts} transactions={transactions} />
}

function EventRuleManagerInternal(props) {
  // Rule Management state
  const [showOnlyAffectedEvents, setShowOnlyAffectedEvents] = useState(false);
  const [rule, setRule] = useState(new Rule());
  const setRuleFields = (fieldObj) => {
    return setRule(_.extend(new Rule(), rule, fieldObj));
  };
  const [ruleIndexBeingEdited, setRuleIndexBeingEdited] = useState(-1);
  const resetRuleState = () => {
    setRuleIndexBeingEdited(-1);
    setRule(new Rule())
  };

  const saveRule = (e) => {
    e.preventDefault();

    if (ruleIndexBeingEdited < 0 || ruleIndexBeingEdited >= props.worldState.rules.length) {
      window.alert(`Trying to edit rule with bad index ${ruleIndexBeingEdited}`);
      return;
    }

    props.worldState.rules.splice(ruleIndexBeingEdited, 1, rule);
    props.handleSave();
    resetRuleState();
  }

  const addNewRule = (e) => {
    e.preventDefault();
    props.worldState.addRule(rule.clone());
    props.handleSave();
    resetRuleState();
  };

  const {isLoadingTxs, isLoadingReceipts, transactions} = props;

  if (isLoadingTxs || isLoadingReceipts) {
    return <div>Loading...</div>;
  }

  const events = _.flatten(transactions.map(tx => tx.events || []));

  const dataFieldsToInclude = ['tx', 'from', 'to', 'name', 'contractAddress', 'args', 'rules', 'effectOfEvent', 'effectOfRule'];
  const cols = buildColumns(props.worldState).filter(col => dataFieldsToInclude.includes(col.dataField));

  const eventsAfterShouldApply = events.map(evt => {
    const shouldApply = rule.shouldApply(evt, evt.tx, props.worldState);
    const effectOfEvent = props.worldState.effectOfEvent(evt);
    return _.extend({}, evt, {filteredByRule: shouldApply, effectOfEvent});
  });

  const eventsAfterApply = eventsAfterShouldApply.map(evt => {
    if (evt.filteredByRule !== true) {
      return evt;
    }

    const effect = rule.apply(evt, evt.tx, props.worldState);
    return _.extend({}, evt, {effectOfRule: effect});
  });

  const rowStyler = (row, rowIndex) => (row.filteredByRule === true) ? {background: filterColor} : {};

  const filterError = _.find(_.map(eventsAfterApply, 'filteredByRule'), val => val instanceof Error);
  const effectError = _.find(_.map(eventsAfterApply, 'effectOfRule'), val => val instanceof Error);

  const eventsToShow = _.map(showOnlyAffectedEvents ? eventsAfterApply.filter(evt => evt.filteredByRule === true) : eventsAfterApply, evt =>
    _.extend({}, evt, {rules: props.worldState.rulesThatApply(evt, evt.tx)})
  );

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">Event Rules</h3>
          <InfoTooltip title={'Event Rules'}>
            <div style={{minWidth: 300}}>
              <div>
                Blockchain transactions produce Events to signal when something happens - like a token or NFT changing hands. This page allows you
                to create rules that transform these events into Balance diffs.
              </div>

              <div className="mt-2">
              For the more technically inclined, this page allows you to implement myFilter and myBalanceEffectMaker in:
              <p className="mt-2 text-monospace">events.filter(myFilter).map(myBalanceEffectMaker)</p>
            </div>
          </div>
          </InfoTooltip>
      </div>
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <div onClick={resetRuleState} style={{paddingBottom: 30}}>
                <div className="row">
                  <h4 className="card-title">Existing Rules</h4>
                </div>
                <div className="row">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.worldState.rules.map((rule, i) =>
                          <tr style={ruleIndexBeingEdited === i ? {background: filterColor} : {}} key={rule.uniqueKey} onClick={(evt) => {
                            setRuleIndexBeingEdited(i);
                            setRule(rule);
                            evt.stopPropagation();
                          }}>
                            <td><div className="badge badge-pill badge-success">{rule.name}</div></td>
                            <td><button onClick={() => {
                              if (window.confirm('Are you sure you wish to delete this item?')) {
                                props.worldState.removeRule(rule);
                                props.handleSave();
                              }
                            }} className="btn btn-danger btn-sm">Delete</button></td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row">
                <h4 className="card-title">Rule</h4>
              </div>
              <div className="row mb-3">
                <form className="forms-sample">
                  <Form.Group>
                    <label htmlFor="exampleInputUsername1">Rule name</label>
                    <Form.Control type="text" id="exampleInputUsername1" placeholder="MyRule"
                        value={rule.name} onChange={(e) => setRuleFields({name: e.target.value})} />
                  </Form.Group>
                  <div>
                    <button disabled={ruleIndexBeingEdited < 0 || ruleIndexBeingEdited >= props.worldState.rules.length} onClick={saveRule}
                      className="btn btn-primary btn-fw mr-3">
                      Save
                    </button>
                    <button onClick={addNewRule} className="btn btn-primary btn-fw">Add new rule</button>
                  </div>
                </form>
              </div>
              <div className="row">
                <div className="col-md-6 grid-margin">
                  <div>
                    <label>Filter</label>
                    <AceEditor style={{minHeight: '100px'}}
                      mode="javascript"
                      theme="monokai"
                      name="jsEditor"
                      editorProps={{ $blockScrolling: true }}
                      placeholder="return evt.name == 'Transfer' && gltx.tx.from == myAddr;"
                      fontSize={14}
                      showPrintMargin={true}
                      showGutter={true}
                      highlightActiveLine={true}
                      height="100%"
                      width="100%"
                      value={rule.filterCode}
                      onChange={val => setRuleFields({filterCode: val})}
                    />
                    {filterError && rule.filterCode !== '' &&
                      <div style={{background: 'red'}}>{filterError.message}</div>
                    }
                  </div>
                </div>
                <div className="col-md-6 grid-margin">
                  <div>
                    <label>Balance Effect</label>
                    <AceEditor style={{minHeight: '100px'}}
                      mode="javascript"
                      theme="monokai"
                      name="jsEditor"
                      editorProps={{ $blockScrolling: true }}
                      placeholder="return {jewel: evt.value};"
                      fontSize={14}
                      showPrintMargin={true}
                      showGutter={true}
                      highlightActiveLine={true}
                      height="100%"
                      width="100%"
                      value={rule.effectCode}
                      onChange={val => setRuleFields({effectCode: val})}
                    />
                    {effectError && rule.effectCode !== '' &&
                      <div style={{background: 'red'}}>{effectError.message}</div>
                    }
                  </div>
                </div>
              </div>
              <div className="row mt-3" style={{justifyContent: 'space-between'}}>
                <h4 className="card-title">Event Rule Simulator</h4>
                <label className="form-check-label text-muted">
                  <input type="checkbox" value={showOnlyAffectedEvents}
                    onClick={() => setShowOnlyAffectedEvents(!showOnlyAffectedEvents)} className="form-check-input"/>
                  <i className="input-helper"></i>
                  Show only affected events
                </label>
              </div>
              <div className="row">
                <ToolkitProvider
                  sizePerPage={50}
                  keyField="timestamp"
                  bootstrap4
                  data={ eventsToShow }
                  columns={ cols }
                  search={{searchFormatted: true}}
                >
                  {
                    props => (
                      <div>
                        <BootstrapTable
                          rowStyle={rowStyler}
                          defaultSorted={[{dataField: 'timestamp', order: 'desc'}]}
                          pagination={ paginationFactory() }
                          { ...props.baseProps }
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )
                  }
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function useTokenBalances(contracts, addr) {
  const [balances, setBalances] = useState({});

  useEffect(() => {
    async function fetchBalance(contract) {
      const balance = await contract.connect().methods.balanceOf(addr).call()
      setBalances(prevState => _.extend({}, prevState, {[contract.address]: BigInt(balance)}));
    }

    _.forEach(contracts, fetchBalance);
  }, [addr, contracts]);

  return balances;
}

async function fetchPrice(code) {
  try {
    const AsyncFunction = Object.getPrototypeOf(async function(){}).constructor;
    return await new AsyncFunction(code)();
  } catch(err) {
    return err;
  }
}

export function PriceFetcherManager(props) {
  const [contractIndexBeingEdited, setContractIndexBeingEdited] = useState(-1);
  const [fetchedPricesByAddr, setFetchedPricesByAddr] = useState({});
  const assetContracts = props.worldState.contracts.filter(c => c.isAsset())
  const contract = (contractIndexBeingEdited >= 0 && contractIndexBeingEdited < assetContracts.length) ? assetContracts[contractIndexBeingEdited] : undefined;
  const priceFetchersByAddr = _.fromPairs(_.map(assetContracts, contract => [contract.address, contract.priceFetcher]))
  const balances = useTokenBalances(assetContracts, props.worldState.defaultAddr);

  const valuesByAddr = _.mapValues(fetchedPricesByAddr, (price, addr) =>
    (_.isNumber(price) && balances[addr]) ? BigInt(price) * balances[addr] : price
  )

  const activeFetchedPrice = contract && fetchedPricesByAddr[contract.address];

  useEffect(() => {
    async function doFetch(addr) {
      const price = await fetchPrice(priceFetchersByAddr[addr]);
      if (price) {
        setFetchedPricesByAddr(prevState => _.extend({}, prevState, {[addr]: price}));
      }
    }

    _.forEach(_.keys(priceFetchersByAddr), (addr) => {
      if (!(addr in fetchedPricesByAddr)) {
        doFetch(addr);
      }
    });
  }, [priceFetchersByAddr, fetchedPricesByAddr]);

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">Historical Price Fetchers</h3>
        <span>
          <InfoTooltip title={'Price Fetchers'}>
            Historical prices for ERC20 or ERC721 tokens depend heavily on the token. Use this page to create historical price fetchers for each of the tokens you're interested in.
          </InfoTooltip>
        </span>
      </div>
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <div className="row" style={{justifyContent: 'space-between'}}>
                <h4 className="card-title">Asset Contracts</h4>
              </div>
              <div className="row">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Balance</th>
                        <th>Value</th>
                        <th>Blockchain</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {assetContracts.map((contract, i) =>
                        <tr style={contractIndexBeingEdited === i ? {background: filterColor} : {}} key={contract.address}
                          onClick={evt => {
                            setContractIndexBeingEdited(i);
                            evt.stopPropagation();
                        }}>
                          <td>{contract.name}</td>
                          <td>{contract.address}</td>
                          <td>{(contract.address in balances)
                            ? formatTokenValue(balances[contract.address], contract.tokenName)
                            : `?? ${contract.tokenName}`
                          }</td>
                          <td>{contract.address in valuesByAddr && formatFiatValue(valuesByAddr[contract.address])}</td>
                          <td>{contract.blockchain}</td>
                          <td><button onClick={() => {
                            if (window.confirm('Are you sure you wish to delete this item?')) {
                              props.worldState.removeContract(contract);
                              props.handleSave();
                            }
                          }} className="btn btn-danger btn-sm">Delete</button></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              {contract &&
                <div>
                  <div className="col-md-6 grid-margin">
                    <div className="form-group">
                      <h4 className="card-title">Price Fetcher</h4>
                      <AceEditor style={{minHeight: '300px'}}
                        mode="javascript"
                        theme="monokai"
                        name="jsEditor"
                        editorProps={{ $blockScrolling: true }}
                        placeholder="return evt.name == 'Transfer' && gltx.tx.from == myAddr;"
                        fontSize={14}
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        height="100%"
                        width="100%"
                        value={contract.priceFetcher}
                        onChange={val => {
                          contract.priceFetcher = val;
                          props.handleSave();
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 grid-margin">
                  {activeFetchedPrice instanceof Error &&
                    <div style={{background: 'red'}}>{activeFetchedPrice.message}</div>
                  }
                  {_.isNumber(activeFetchedPrice) &&
                    <div style={{background: 'green'}}>{formatFiatValue(activeFetchedPrice * 10 ** 18)}</div>
                  }
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const TextControl = (label, value, onChange) => {
  return (
    <Form.Group>
      <label htmlFor="exampleInputUsername1">{label}</label>
      <Form.Control type="text" id="exampleInputUsername1" value={value} onChange={(e) => onChange(e.target.value)} />
    </Form.Group>
  );
};

export const NumberControl = (label, value, onChange) => {
  return (
    <Form.Group>
      <label htmlFor="exampleInputUsername1">{label}</label>
      <Form.Control type="number" id="exampleInputUsername1" value={value} onChange={(e) => onChange(Number(e.target.value))} />
    </Form.Group>
  );
};

export const DateControl = (label, value, onChange) => {
  return (
    <Form.Group className="row">
      <label className="col-sm-3 col-form-label">{label}</label>
      <div className="col-sm-9">
      <DatePicker className="form-control w-100"
        selected={value}
        onChange={onChange}
      />
      </div>
    </Form.Group>
  );
}


export const CheckboxControl = (label, value, onChange) => {
  return (
    <div className="form-check">
      <label className="form-check-label text-muted">
        <input type="checkbox" checked={value} readOnly onClick={() => onChange(!value)} className="form-check-input"/>
        <i className="input-helper"></i>
        {label}
      </label>
    </div>
  );
};

export const RadioControlMaker = (options, labels = undefined) => {
  assert(() => _.every(options, _.isString));
  labels ??= options;
  return (label, value, onChange) => {
    return (
      <Form.Group style={{display: 'flex'}}>
      {options.map((option, i) =>
        <span key={option} className="form-check mr-2">
          <label className="form-check-label" onClick={() => onChange(option)}>
            <input type="radio" className="form-check-input"
              checked={value === option} readOnly />
            <i className="input-helper"></i>
            {labels[i]}
          </label>
        </span>
      )}
      </Form.Group>
    );
  };
};

const renderModel = (ty, value) => {
  if (ty === String) {
    return value;
  } else if (ty === Boolean) {
    return <input type="checkbox" checked={value} readOnly/>;
  } else if (_.isObject(ty) && ty.__isEnum) {
    return value;
  } else if (ty === Date) {
    throw new Error("date not implemented");
  } else if (ty === Number) {
    return value.toString();
  } else if (ty === JSON) {
    return value.toString();
  } else if (_.isArray(ty) && ty.length === 1) {
    return value.toString();
  } else if (value.render) {
    return value.render();
  } else {
    return (
      <ul>
      {_.map(ty.properties, (ty, prop) =>
        <li key={prop}>{prop}: {renderModel(ty, value[prop])}</li>
      )}
      </ul>
    );
  }
};

export const formControlForType = (ty) => {
  if (ty === String) {
    return TextControl;
  } else if (ty === Boolean) {
    return CheckboxControl;
  } else if (_.isObject(ty) && ty.__isEnum) {
    return RadioControlMaker(ty.options);
  } else if (ty === Number) {
    return NumberControl;
  } else if (ty === Date) {
    return DateControl;
  } else if (ty === JSON) {
    throw new Error("JSON control not implemented");
  } else if (_.isArray(ty) && ty.length === 1) {
    return (label, value, onChange) => {
      return (
        <ul>
        {value.map((elem, i) =>
          <li key={elem.uniqueKey}>
          {formControlForType(ty[0])(`${label}[${i}]`, elem, newElem => {
            onChange(value.map((elem, j) => j === i ? newElem : elem))
          })}
          </li>
        )}
        </ul>
      );
    }
  } else if (ty.formControl) {
    return ty.formControl();
  } else {
    return (label, value, onChange) => {
      return <ModelEditor title={label} modelInstance={value} setModelInstance={onChange} />
    }
  }
}

export function ModelEditor(props) {
  const model = props.modelInstance.constructor;
  return (
    <div className="forms-sample">
      <div className="row">
        <h4 className="card-title">{props.title || props.modelInstance.constructor.displayName}</h4>
      </div>

      {_.map(model.properties, (ty, prop) => {
        const [label, value, onChange] = [prop, props.modelInstance[prop], newVal =>
          props.setModelInstance(extendModel(props.modelInstance, {[prop]: newVal}))
        ];
        return (
          <div key={prop}>
            {prop in (model.overrideControls || {})
              ? model.overrideControls[prop](props.modelInstance)(label, value, onChange)
              : formControlForType(ty)(label, value, onChange)
            }
          </div>
        );
      })}
    </div>
  );
}

export function ListOfModelsManager(props) {
  const requiredProps = ['elements', 'model', 'addElement', 'replaceElement', 'removeElement', 'handleSave'];
  assert(() => _.every(requiredProps, p => props[p] !== undefined));

  const [editableElement, setEditableElement] = useState(new props.model());
  const indexBeingEdited = _.findIndex(props.elements, {uniqueKey: editableElement.uniqueKey});

  const resetEditState = () => {
    setEditableElement(new props.model());
  };
  const addNewElement = (e) => {
    e.preventDefault();

    try {
      props.addElement(editableElement.cloneWithNewKey());
      props.handleSave();
      resetEditState();

    } catch (err) {
      window.alert(`Adding new element failed: ${err.message}`);
    }
  }
  const saveElement = (e) => {
    e.preventDefault();

    try {
      if (indexBeingEdited === -1) {
        throw new Error("Trying to save element that's not in the list");
      }
      props.replaceElement(indexBeingEdited, editableElement);
      props.handleSave();
      resetEditState();

    } catch (err) {
      window.alert(`Element save failed: ${err.message}`);
    }
  }

  return (
    <div className="row">
      <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <div onClick={() => {
                if (indexBeingEdited !== -1) {
                  resetEditState()
                }
              }} style={{paddingBottom: 30}}>
                <div className="row" style={{justifyContent: 'space-between'}}>
                  <h4 className="card-title">{props.model.displayName}s</h4>
                </div>
                <div className="row">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          {_.map(props.model.properties, (ty, prop) =>
                            <th key={prop}>{prop}</th>
                          )}
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.elements.map((elem, i) =>
                          <tr style={indexBeingEdited === i ? {background: filterColor} : {}} key={elem.uniqueKey}
                            onClick={evt => {
                              setEditableElement(elem);
                              evt.stopPropagation();
                          }}>
                            {_.map(props.model.properties, (ty, prop) =>
                              <td key={prop}>{renderModel(ty, elem[prop])}</td>
                            )}
                            <td><button onClick={(evt) => {
                              if (window.confirm('Are you sure you wish to delete this item?')) {
                                props.removeElement(elem);
                                props.handleSave();
                                resetEditState();
                                evt.stopPropagation();
                              }
                            }} className="btn btn-danger btn-sm">Delete</button></td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <ModelEditor modelInstance={editableElement} setModelInstance={setEditableElement}  />

              <div>
                <button disabled={indexBeingEdited < 0 || indexBeingEdited >= props.elements.length} onClick={saveElement}
                  className="btn btn-primary btn-fw mr-3">
                  Save
                </button>
                <button onClick={addNewElement} className="btn btn-primary btn-fw">Add new</button>
              </div>

            </div>
          </div>
        </div>
      </div>
  );
}

export function LedgerAccountsManager(props) {
  const {ledger, handleSave} = props;
  return (
    <ListOfModelsManager elements={ledger.accounts} model={LedgerAccount} title={'Ledger Accounts'} handleSave={handleSave}
      addElement={e => ledger.addAccount(e)} replaceElement={(i, e) => ledger.replaceAccount(i, e)} removeElement={e => ledger.removeAccount(e)}
    />
  );
}

export function LedgerTransactionsManager(props) {
  const requiredProps = ['ledger', 'handleSave'];
  assert(() => _.every(requiredProps, p => props[p] !== undefined));

  const {ledger, handleSave} = props;

  const newTwoEntryTransaction = () => {
    const tx = new LedgerTransaction({entries: [new LedgerEntry(), new LedgerEntry()]});
    tx.setLedgerReference(ledger);
    return tx
  }

  const [editableElement, setEditableElement] = useState(newTwoEntryTransaction());
  const indexBeingEdited = _.findIndex(ledger.transactions, {uniqueKey: editableElement.uniqueKey});

  const resetEditState = () => {
    setEditableElement(newTwoEntryTransaction());
  };
  const addNewElement = (e) => {
    e.preventDefault();

    try {
      ledger.addTransaction(editableElement.cloneWithNewKey());
      handleSave();
      resetEditState();

    } catch (err) {
      window.alert(`Adding new element failed: ${err.message}`);
    }
  }
  const saveElement = (e) => {
    e.preventDefault();

    try {
      if (indexBeingEdited === -1) {
        throw new Error("Trying to save element that's not in the list");
      }
      ledger.replaceTransaction(indexBeingEdited, editableElement);
      handleSave();
      resetEditState();

    } catch (err) {
      window.alert(`Element save failed: ${err.message}`);
    }
  }

  return (
    <div className="row">
      <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <div onClick={() => {
                if (indexBeingEdited !== -1) {
                  resetEditState()
                }
              }} style={{paddingBottom: 30}}>
                <div className="row" style={{justifyContent: 'space-between'}}>
                  <h4 className="card-title">Ledger Transactions</h4>
                </div>
                <div className="row">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Timestamp</th>
                          <th>Impact</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.map(ledger.transactions, (tx, i) =>
                          <tr style={indexBeingEdited === i ? {background: filterColor} : {}} key={tx.uniqueKey}
                            onClick={evt => {
                              setEditableElement(tx);
                              evt.stopPropagation();
                          }}>
                            <td>{tx.accrualTime.toLocaleString()}</td>
                            <td>
                              <ul>
                              {tx.entries.map(e =>
                                <li key={e.uniqueKey}>{`${e.amount}${e.account().asset.tokenName}`}</li>
                              )}
                              </ul>
                            </td>
                            <td><button onClick={(evt) => {
                              if (window.confirm('Are you sure you wish to delete this item?')) {
                                ledger.removeTransaction(tx);
                                handleSave();
                                resetEditState();
                                evt.stopPropagation();
                              }
                            }} className="btn btn-danger btn-sm">Delete</button></td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <ModelEditor modelInstance={editableElement} setModelInstance={setEditableElement}  />

              <div>
                <button disabled={indexBeingEdited < 0 || indexBeingEdited >= ledger.transactions.length} onClick={saveElement}
                  className="btn btn-primary btn-fw mr-3">
                  Save
                </button>
                <button onClick={addNewElement} className="btn btn-primary btn-fw">Add new</button>
              </div>

            </div>
          </div>
        </div>
      </div>
  );
}

const availableCurrencies = [
  {name: 'USD', format: formatFiatValue},
  {name: 'ETH', format: (val) => formatTokenValue(val, 'ETH')},
  {name: 'BTC', format: (val) => formatTokenValue(val, 'BTC')},
];

export function LedgerBalances(props) {
  const [referenceCurrency, setReferenceCurrency] = useState(availableCurrencies[0]);

  const [dailyInvestmentValues, setDailyInvestmentValues] = useState([]);
  const [pricesByAccountKey, setPricesByAccountKey] = useState({});
  const [historyLoading, setHistoryLoading] = useState(false);
  const ledger = props.ledger;
  const accounts = ledger.investmentAccounts();
  const {lp} = useParams();
  const lpAccount = lp && ledger.accountByKey(lp);

  const reloadPrices = () => {
    setDailyInvestmentValues([]);
    setPricesByAccountKey({});
    setHistoryLoading(false);
  }

  useEffect(() => {
    async function fetchPrices() {
      const ret = _.fromPairs(await Promise.all(accounts.map(async a => [`${a.uniqueKey}`, await a.asset.priceFetcher.fetch(referenceCurrency)])));
      setPricesByAccountKey(ret);
    }

    async function fetchDailyInvestmentValues() {
      setHistoryLoading(true);
      if (lpAccount) {
        assert(() => lpAccount.firstTransaction());
        const days = moment().diff(moment(lpAccount.firstTransaction().accrualTime), 'days');
        setDailyInvestmentValues(await ledger.dailyInvestmentValuesForLp(Math.max(days, 1), lp, referenceCurrency));
      } else {

        setDailyInvestmentValues(await ledger.dailyNormalizedInvestmentValues(180, referenceCurrency));
      }

    }

    if (historyLoading === false) {
      fetchDailyInvestmentValues();
      fetchPrices();
    }
  }, [accounts, referenceCurrency, historyLoading, ledger, lp, lpAccount]);

  if (_.keys(pricesByAccountKey).length === 0 || dailyInvestmentValues.length === 0) {
    return <div>Loading</div>
  }

  const graphData = _.reverse(dailyInvestmentValues);
  const currentKakashiTokenPrice = _.sumBy(accounts, acc => acc.finalBalance() * pricesByAccountKey[acc.uniqueKey]) / ledger.totalEquityTokens();
  const sortedAccounts = _.reverse(_.sortBy(accounts, a => a.finalBalance() * pricesByAccountKey[a.uniqueKey]));

  const imagesForLp = {
    hinata: 'hinata.jpeg',
    youngMinato: 'youngMinato.gif',
    maitoGai: 'maitoGai.jpeg',
    kakashi: 'kakashi.jpeg',
    hashirama: 'hashirama.jpeg',
    pikachu: 'pikachu.gif',
    naruto: 'naruto.webp',
    alice: 'alice.gif',
  }

  return (
    <div className="col-12 grid-margin">
      <div className="card">
        <div className="card-body">
          <div style={{paddingBottom: 30}}>
            {lpAccount &&
              <div className="row" style={{marginBottom: 20, justifyContent: 'space-between'}}>
                <div>
                  <h4 className="card-title">Hello, world!</h4>
                  <div>
                    Hello, <strong>{lpAccount.name}</strong>! Welcome to the Kakashi Pool :)
                  </div>
                  <div>
                    Your share of the pool is worth <strong>{referenceCurrency.format(currentKakashiTokenPrice * lpAccount.finalBalance())}</strong>
                  </div>
                  <div>
                     Below you can track your portfolio value over time, and see all the assets underlying it.
                  </div>
                </div>
                <img src={require(`../assets/images/${imagesForLp[lp]}`)} alt="dfk" style={{marginLeft: 20, height: 200}} />
              </div>
            }
            <div className="row" style={{justifyContent: 'space-between'}}>
              <h4 className="card-title">Balance over time</h4>

              <Dropdown>
                <Dropdown.Toggle variant="btn btn-primary" id="dropdownMenuButton1">
                  {referenceCurrency.name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {availableCurrencies.map(currency =>
                    <Dropdown.Item key={currency.name} onClick={() => {
                      setReferenceCurrency(currency);
                      reloadPrices();
                    }}>{currency.name}</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="row">
            {graphData &&
                <PriceGraph data={graphData} />
            }
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <div>
                  {lpAccount &&
                    <div>$KAKASHI held: <strong>{lpAccount.finalBalance()}</strong></div>
                  }
                  <div>Current price of $KAKASHI: <strong>{referenceCurrency.format(currentKakashiTokenPrice)}</strong></div>
                  {lpAccount
                    ? <div>Current value of Portfolio: <strong>{referenceCurrency.format(currentKakashiTokenPrice * lpAccount.finalBalance())}</strong></div>
                    : <div>Total outstanding KAKASHI tokens: <strong>{ledger.totalEquityTokens()}</strong></div>
                  }
                </div>
                <img src={require('../assets/images/baby-kurama.png')} alt="dfk" style={{marginLeft: 20, height: 200}} />
              </div>
            </div>

            <div className="row" style={{marginTop: 20}}>
              <h4 className="card-title">Ledger Balances</h4>
            </div>
            <div className="row">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Account</th>
                      <th>Price per Token</th>
                      <th>Balance</th>
                      <th>Total Value</th>
                      <th>Normalized Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(sortedAccounts, (account) => {
                      const price = pricesByAccountKey[account.uniqueKey];
                      return (
                        <tr key={account.uniqueKey}>
                          <td>{account.name}</td>
                          <td>{price ? referenceCurrency.format(price) : 'Loading...' }</td>
                          <td>{account.finalBalance()}</td>
                          <td>{_.isNumber(price) ? referenceCurrency.format(account.finalBalance() * price) :  'Loading...' }</td>
                          <td>{_.isNumber(price) ? referenceCurrency.format(account.finalBalance() * price / ledger.totalEquityTokens()) :  'Loading...' }</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}



function PriceGraph(props) {
  return (
    <div style={{height: 458, width: 649, cursor: 'default'}}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export function EquityDashboard(props) {
  return (
    <div className="col-12 grid-margin">
      <div className="card">
        <div className="card-body">
          <div style={{paddingBottom: 30}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <img src={require('../assets/images/many-characters.png')} alt="dfk" style={{width: 400}} />
              <div>Total Supply: <strong>{formatTokenValue(props.ledger.totalEquityTokens(), 'KAKASHI')}</strong></div>
            </div>

            <div className="row" style={{marginTop: 20}}>
              <h4 className="card-title">Equity Split</h4>
            </div>
            <div className="row">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Account</th>
                      <th>Kakashi Tokens</th>
                      <th>% Equity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(props.ledger.equityAccounts(), (account) => {
                      return (
                        <tr key={account.uniqueKey}>
                          <td>{account.name}</td>
                          <td>{formatTokenValue(account.finalBalance(), 'KAKASHI')}</td>
                          <td>{formatPercentage(account.finalBalance() / props.ledger.totalEquityTokens())}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
