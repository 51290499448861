import React, { useReducer, useState, useEffect, Suspense } from 'react';
import './App.scss';
import moment from 'moment';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { Enum, Model } from './model';
import abiCoder from 'web3-eth-abi';
import { sha3, BN } from "web3-utils";
import { Harmony } from "@harmony-js/core";
import defaultWorldStateJson from './default-world-state.json';
import { ChainType } from "@harmony-js/utils";

import { Routes, Route } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';

import {Balances} from './accounting';
import {assert, isAddress, parseJsonWithBigInts, stringifyJsonWithBigInts, addressesEqual, normalizeAddress } from './utils';

import {EquityDashboard, RadioControlMaker, LedgerBalances } from './Pages';
import _ from 'lodash';
import {allBlockchains, seedWorldState} from './seed';
import {PriceFetcher} from './price-fetchers';


// Only turn to true if you know what you're doing
const DANGEROUSLY_SEED_WORLD_STATE = true;


/* global BigInt */
const _knownAbis = {}

const sign = (me, from, to) => {
  const fromMe = addressesEqual(me, from);
  const toMe = addressesEqual(me, to);
  if (fromMe) {
    return BigInt(-1);
  } else if (toMe) {
    return BigInt(+1);
  } else {
    return BigInt(0);
  }

};


export const Asset = Model.register('asset', class Asset extends Model {
  static properties = {
    name: String,
    tokenName: String,
    type: Enum(String, ['ERC20', 'ERC721', 'Other']),
    contractAddress: String,
    priceFetcher: PriceFetcher,
    blockchain: Enum(String, allBlockchains),
  }
});

export const LpTokenAsset = Model.register('lp-token-asset', class LpTokenAsset extends Asset {
  static properties = {
    constant: Number,
    asset1: Asset,
    asset2: Asset,
    tokenName: String,
    type: Enum(String, ['ERC20']),
    contractAddress: String,
    blockchain: Enum(String, allBlockchains),
    priceFetcher: undefined,
  }

  constructor(json) {
    super(json);
    this.priceFetcher = {
      fetch: this.fetch.bind(this),
      fetchHistory: this.fetchHistory.bind(this),
    }
  }

  async quantities() {
    const [price1, price2] = [await this.asset1.priceFetcher.fetch({name: 'usd'}), await this.asset2.priceFetcher.fetch({name: 'usd'})];
    return this.quantitiesGivenPrices(price1, price2);
  }

  // Assume K1 is the AMM constant when we have exactly one LP token in the pool.
  // From the Uniswap formula, we derive:
  // K1 = q1/LPTokens * q2 / LPTokens
  // K1 = q1 * q2
  // q2 / q1 = p1 / p2
  //
  // So:
  // q1^2 = K1 * p2 / p1
  priceOfLpToken(price1, price2) {
    const [q1, q2] = this.quantitiesGivenPrices(price1, price2);
    return q1 * price1 + q2 * price2;
  }

  quantitiesGivenPrices(price1, price2) {
    const q1 = Math.sqrt(this.constant * price2 / price1);
    const q2 = this.constant / q1;
    return [q1, q2];
  }

  async fetch(currency) {
    const [price1, price2] = [await this.asset1.priceFetcher.fetch(currency), await this.asset2.priceFetcher.fetch(currency)];
    return this.priceOfLpToken(price1, price2);
  }

  async fetchHistory(currency) {
    const [history1, history2] = [await this.asset1.priceFetcher.fetchHistory(currency), await this.asset2.priceFetcher.fetchHistory(currency)];
    return history1.map(([t1, price1]) => {
      const found = _.find(history2, t2 => moment(t1).format('l') === moment(t2).format('l'));
      if (found === undefined) {
        return undefined;
      }
      return [t1, this.priceOfLpToken(price1, found[1])];
    }).filter(x => x !== undefined);
  }
});

export const MapPriceFetcher = Model.register('map-price-fetcher', class MapPriceFetcher extends PriceFetcher {
  static properties = {
    underlyingAsset: Asset,
  };

  clone() {
    const clone = super.clone()
    clone.map = this.map;
    return clone;
  }

  async fetch(currency = {name: 'usd'}) {
    return this.map(currency, await this.underlyingAsset.priceFetcher.fetch(currency));
  }

  async fetchHistory(currency = {name: 'usd'}) {
    const priceHistory = await this.underlyingAsset.priceFetcher.fetchHistory(currency);
    return priceHistory.map(([timestamp, price]) => [timestamp, this.map(currency, price)]);
  }
});



export const LedgerAccount = Model.register('ledger-account', class LedgerAccount extends Model {
  static properties = {
    name: String,
    asset: Asset,
    type: Enum(String, ['Investment', 'Equity']),
  }

  static displayName = 'Ledger Account'

  balanceOn(datetime) {
    assert(() => !_.isEmpty(this.ledger));

    const entriesBeforeDatetime = _.flatten(_.map(this.ledger.transactions.filter(tx => tx.accrualTime < datetime), tx => tx.entries));
    return _.sumBy(_.filter(entriesBeforeDatetime, {accountUniqueKey: this.uniqueKey}), 'amount');
  }

  balanceAfterTx(index) {
    assert(() => !_.isEmpty(this.ledger));
    assert(() => index >= 0 && index < this.ledger.transactions.length);

    const entriesUntilIndex = _.flatten(_.map(this.ledger.transactions.slice(0, index + 1), tx => tx.entries));
    return _.sumBy(_.filter(entriesUntilIndex, {accountUniqueKey: this.uniqueKey}), 'amount');
  }

  firstTransaction() {
    return _.find(this.ledger.sortedTransactions(), tx => tx.touches(this.uniqueKey));
  }

  finalBalance() {
    return this.ledger.transactions.length === 0 ? 0 : this.balanceAfterTx(this.ledger.transactions.length - 1);
  }

  anyError() {
    if (_.isEmpty(this.name)) {
      return new Error('Account must have a non-empty name');
    }
    return undefined;
  }
});

export const LedgerEntry = Model.register('ledger-entry', class LedgerEntry extends Model {
  static properties = {
    accountUniqueKey: String,
    amount: Number,
  }

  static overrideControls = {
    accountUniqueKey: (instance) =>
      RadioControlMaker(instance.ledger.accounts.map(acc => acc.uniqueKey), instance.ledger.accounts.map(acc => acc.name))
  }

  anyError() {
    if (_.isEmpty(this.account())) {
      return new Error(`Entry references account ${this.accountUniqueKey} which does not exist`);
    }
    return undefined;
  }

  account() {
    assert(() => !_.isEmpty(this.ledger));
    return _.find(this.ledger.accounts, {uniqueKey: this.accountUniqueKey});
  }

  clone() {
    return _.extend(super.clone(), {ledger: this.ledger});
  }
});

export const LedgerTransaction = Model.register('ledger-transaction', class LedgerTransaction extends Model {
  static properties = {
    entries: [LedgerEntry],
    accrualTime: Date,
  }

  touches(accountUniqueKey) {
    return _.some(this.entries, e => e.accountUniqueKey === accountUniqueKey);
  }

  balanceImpactOnAccount(account) {
    return _.find(this.entries, {accountUniqueKey: account.uniqueKey})?.amount || 0;
  }

  anyError() {
    if (this.entries.length < 1) {
      return new Error("Ledger Transaction must have at least one entry");
    }

    if (_.uniqBy(this.entries, 'accountUniqueKey').length !== this.entries.length) {
      return new Error("Ledger Transaction cannot have 2 different entries in the same account");
    }

    const entryError = _.find(this.entries.map(entry => entry.anyError()));
    if (entryError) {
      return entryError;
    }

    return undefined;
  }

  clone() {
    return _.extend(super.clone(), {ledger: this.ledger, entries: this.entries.map(e => e.clone())});
  }

  setLedgerReference(ledger) {
    this.ledger = ledger;
    _.forEach(this.entries, e => e.ledger = ledger);
  }
});

export const Ledger = Model.register('ledger', class Ledger extends Model {
  static properties = {
    accounts: [LedgerAccount],
    transactions: [LedgerTransaction],
  }

  constructor(json) {
    super(json);

    _.forEach(this.accounts, account => account.ledger = this);
    _.forEach(this.transactions, tx => tx.setLedgerReference(this));
  }

  investmentAccounts() {
    return this.accounts.filter(a => a.type === 'Investment');
  }

  equityAccounts() {
    return this.accounts.filter(a => a.type === 'Equity');
  }

  accountByKey(uniqueKey) {
    return _.find(this.accounts, {uniqueKey});
  }

  totalEquityTokens() {
    return _.sumBy(this.equityAccounts(), a => a.finalBalance());
  }

  totalEquityTokensOn(datetime) {
    return _.sumBy(this.equityAccounts(), a => a.balanceOn(datetime));
  }

  anyError() {
    const txError = _.find(this.transactions.map(tx => tx.anyError()));
    if (txError) {
      return txError;
    }

    const accountError = _.find(this.accounts.map(a => a.anyError()));
    if (accountError) {
      return accountError;
    }
    return undefined;
  }

  addAccount(newAccount) {
    this.throwIfErrorFromChange(clone => {
      newAccount.ledger = this;
      clone.accounts.push(newAccount);
      return clone;
    });

    newAccount.ledger = this;
    this.accounts.push(newAccount);
  }

  replaceAccount(index, newAccount) {
    this.throwIfErrorFromChange(clone => {
      newAccount.ledger = this;
      clone.accounts.splice(index, 1, newAccount);
      return clone;
    });

    newAccount.ledger = this;
    this.accounts.splice(index, 1, newAccount);
  }

  removeAccount(account) {
    const index = this.accounts.indexOf(account);
    if (index === -1) {
      return;
    }

    this.throwIfErrorFromChange(clone => {
      clone.accounts.splice(index, 1);
      return clone;
    });

    this.accounts.splice(index, 1)
  }

  addTransaction(tx) {
    this.throwIfErrorFromChange(clone => {
      tx.setLedgerReference(this);
      clone.transactions.push(tx);
      return clone;
    });

    tx.setLedgerReference(this);
    this.transactions.push(tx);
  }

  replaceTransaction(index, tx) {
    this.throwIfErrorFromChange(clone => {
      tx.setLedgerReference(this);
      clone.transactions.splice(index, 1, tx);
      return clone;
    });

    tx.setLedgerReference(this);
    this.transactions.splice(index, 1, tx);
  }

  removeTransaction(tx) {
    const index = this.transactions.indexOf(tx);
    if (index === -1) {
      return;
    }

    this.throwIfErrorFromChange(clone => {
      clone.transactions.splice(index, 1);
      return clone;
    });
    this.transactions.splice(index, 1)
  }

  sortedTransactions() {
    return _.sortBy(this.transactions, 'accrualTime');
  }

  dailyBalances(daysQueried) {
    const dateOfTx = (tx) => moment(tx.accrualTime).format('l');

    let balancesPerDay = {};
    const sortedTxs = _.reverse(this.sortedTransactions());
    for (let account of this.accounts) {
      balancesPerDay[account.uniqueKey] = {0: account.finalBalance()};
    }

    let nextTxToProcess = 0;
    for (let day = 1; day < daysQueried; day++) {
      for (let account of this.accounts) {
        balancesPerDay[account.uniqueKey][day] = balancesPerDay[account.uniqueKey][day - 1];
      }

      while (nextTxToProcess < sortedTxs.length
            && dateOfTx(sortedTxs[nextTxToProcess]) === moment().subtract(day - 1, 'days').format('l')) {
        for (let entry of sortedTxs[nextTxToProcess].entries) {
          balancesPerDay[entry.accountUniqueKey][day] -= entry.amount;
        }

        nextTxToProcess++;
      }
    }

    return balancesPerDay;
  }

  dailyNormalizedBalances(daysQueried) {
    let balancesPerDay = this.dailyBalances(daysQueried);

    // Normalize all investment accounts using the sum of the equity account balances
    for (let day = 0; day < daysQueried; day++) {
      const currentTotalEquity = _.sumBy(this.equityAccounts(), a => balancesPerDay[a.uniqueKey][day]);
      for (let account of this.investmentAccounts()) {
        balancesPerDay[account.uniqueKey][day] /= currentTotalEquity;
      }
    }
    return balancesPerDay;
  }

  dailyBalancesForLp(daysQueried, equityAccountUniqueKey) {
    let balancesPerDay = this.dailyBalances(daysQueried);

    // Normalize all investment accounts using the sum of the equity account balances
    for (let day = 0; day < daysQueried; day++) {
      const currentLpEquity = balancesPerDay[equityAccountUniqueKey][day];
      const currentTotalEquity = _.sumBy(this.equityAccounts(), a => balancesPerDay[a.uniqueKey][day]);
      for (let account of this.investmentAccounts()) {
        balancesPerDay[account.uniqueKey][day] *= currentLpEquity / currentTotalEquity;
      }
    }
    return balancesPerDay;
  }

  async dailyInvestmentValuesForLp(daysQueried, equityAccountUniqueKey, currency = {name: 'usd'}) {
    return await this._dailyInvestmentValuesHelper(this.dailyBalancesForLp(daysQueried, equityAccountUniqueKey), daysQueried, currency);
  }

  async dailyInvestmentValues(daysQueried, currency = {name: 'usd'}) {
    return await this._dailyInvestmentValuesHelper(this.dailyBalances(daysQueried), daysQueried, currency);
  }

  async dailyNormalizedInvestmentValues(daysQueried, currency = {name: 'usd'}) {
    return await this._dailyInvestmentValuesHelper(this.dailyNormalizedBalances(daysQueried), daysQueried, currency);
  }

  async _dailyInvestmentValuesHelper(balancesPerDay, daysQueried, currency) {
    const accounts = this.investmentAccounts();

    let accountPricePerDay = {};
    await Promise.all(accounts.map(async account => {
      const history = await account.asset.priceFetcher.fetchHistory(currency);
      accountPricePerDay[account.uniqueKey] = _.fromPairs(_.map(history, ([timestamp, price]) =>
        [moment(timestamp).format('l'), price]
      ));

      accountPricePerDay[account.uniqueKey][moment().format('l')] = await account.asset.priceFetcher.fetch(currency);
    }));

    return _.range(daysQueried).map(day => {
      const date = moment().subtract(day, 'days').format('l');
      return {
        daysAgo: day,
        name: date,
        value: _.sumBy(accounts, account => {
          const balance = balancesPerDay[account.uniqueKey][day]
          if (balance === 0) {
            return 0;
          }

          if (account.uniqueKey in accountPricePerDay && date in accountPricePerDay[account.uniqueKey]) {
            return accountPricePerDay[account.uniqueKey][date] * balance;
          } else {
            return 0;
          }
        }),
      };
    })
  }

});


export const Rule = Model.register('rule', class Rule extends Model {
  static properties = {
    name: String,
    effectCode: String,
    filterCode: String,
  }

  shouldApply(evt, tx, worldState) {
    const isMyAddr = addr => addressesEqual(worldState.defaultAddr, addr);
    let ret;
    try {
      // eslint-disable-next-line no-new-func
      ret = new Function('evt, tx, isMyAddr', this.filterCode)(evt, tx, isMyAddr);
    } catch(err) {
      return err;
    }

    if (ret !== false && ret !== true) {
      return new Error(`Filter function must return either true or false. Returned: ${ret}`);
    }
    return ret;
  }

  apply(evt, tx, worldState) {
    const isMyAddr = addr => addressesEqual(worldState.defaultAddr, addr);
    let effect = {};
    try {
      // eslint-disable-next-line no-new-func
      effect = new Function('evt, tx, isMyAddr', this.effectCode)(evt, tx, isMyAddr);
    } catch(err) {
      return err;
    }

    if (!(effect instanceof Object) || _.some(_.values(effect), v => isNaN(v))) {
      return new Error(`Effect must return an object of integers representing token deltas. Returned: ${effect}`);
    }
    return new Balances(effect);
  }
});

export const Contract = Model.register('contract', class Contract extends Model {
  static properties = {
    name: String,
    address: String,
    stringifiedAbi: String,
    blockchain: String,
    metadata: JSON,
    type: String,
    tokenName: String,
  }

  static defaultProperties = {
    stringifiedAbi: '[]',
    blockchain: 'Harmony',
    type: 'Other',
  }

  static validBlockchains = ['Harmony'];
  static validTypes = ['ERC20', 'ERC721', 'Other'];

  anyError() {
    if (_.isEmpty(this.name)) {
      return new Error("Name cannot be empty");
    }

    if (!isAddress(this.address)) {
      return new Error(`Address ${this.address} is not valid`);
    }

    if (!this.constructor.validBlockchains.includes(this.blockchain)) {
      return new Error(`Blockchain ${this.blockchain} is invalid`);
    }

    if (!this.constructor.validTypes.includes(this.type)) {
      return new Error(`Type ${this.type} is invalid`);
    }

    if (this.typeRequiresTokenName() && _.isEmpty(this.tokenName)) {
      return new Error(`Contract of type ${this.type} must have a tokenName`);
    }

    try {
      JSON.parse(this.stringifiedAbi);
    } catch(e) {
      return new Error("ABI is not a parseable JSON");
    }

    return undefined;
  }

  typeRequiresTokenName() {
    return this.isAsset();
  }

  isAsset() {
    return ['ERC20', 'ERC721'].includes(this.type);
  }

  connect() {
    const rpc = 'https://api.s0.t.hmny.io/';
    const hmy = new Harmony(rpc, {
      chainType: ChainType.Harmony,
      chainId: 1666600000,
    });

    return hmy.contracts.createContract(
      JSON.parse(this.stringifiedAbi),
      this.address
    );
  }
});

export const WorldState = Model.register('world-state', class WorldState extends Model {
  static properties = {
    ledger: Ledger,
    contracts: [Contract],
    rules: [Rule],
    shouldCacheTransactions: Boolean,
  }

  constructor(json) {
    super(json);

    this.addAllContractAbis();
  }

  decodeReceiptLogs(logs) {
    try {
      return logs.map(log => {
        const contract = this.findContract(log.address);
        return contract ? decodeLog(log, JSON.parse(contract.stringifiedAbi)) : log;
      }).filter(log => log.decoded);
    } catch(err) {
      console.warn("Error decoding logs");
      console.warn(err);
      return undefined;
    }
  }

  decodeContractCall(encodedString, contractAddr) {
    const contract = this.findContract(contractAddr);
    return contract ? decodeMethod(encodedString, JSON.parse(contract.stringifiedAbi)) : encodedString;
  }

  addAllContractAbis() {
    for (const contract of this.contracts) {
      if (!_knownAbis[contract.address]) {
        _knownAbis[contract.address] = true;
      }
    }
  }

  addContract(newContract) {
    this.throwIfErrorFromChange(clone => {
      clone.contracts.push(newContract);
      return clone;
    });

    this.contracts.push(newContract);
    this.addAllContractAbis();
  }

  replaceContract(index, newContract) {
    this.throwIfErrorFromChange(clone => {
      clone.rules.splice(index, 1, newContract);
      return clone;
    });

    this.contracts.splice(index, 1, newContract);
    this.addAllContractAbis();
  }

  removeContract(contract) {
    const index = this.contracts.indexOf(contract);
    if (index === -1) {
      return;
    }
    this.contracts.splice(index, 1)
  }

  addRule(newRule) {
    this.throwIfErrorFromChange(clone => {
      clone.rules.push(newRule);
      return clone;
    });

    this.rules.push(newRule);
  }

  removeRule(rule) {
    const index = this.rules.indexOf(rule);
    if (index === -1) {
      return;
    }
    this.rules.splice(index, 1)
  }

  anyError() {
    if (_.uniqBy(this.contracts, 'address').length !== this.contracts.length) {
      throw new Error("Duplicate contract addresses found")
    }

    // Return any errors in the contracts
    return _.find(_.map(this.contracts, c => c.anyError()), e => e !== undefined);
  }

  findContract(addr) {
    return _.find(this.contracts, c => normalizeAddress(c.address) === normalizeAddress(addr));
  }

  rulesThatApply(evt, tx) {
    return _.filter(this.rules, r => r.shouldApply(evt, tx, this));
  }

  effectOfTransaction(btx) {
    const oneValue = BigInt(btx.value || 0) * sign(this.defaultAddr, btx.from, btx.to) - btx.gasFeePaid;

    let totalEffect = new Balances({ONE: oneValue});
    for (const evt of (btx.events || [])) {
      const effectOfEvent = this.effectOfEvent(evt);
      if (effectOfEvent instanceof Error) {
        return effectOfEvent;
      }
      totalEffect = totalEffect.plus(effectOfEvent);
    }
    return totalEffect;
  }

  effectOfEvent(evt) {
    let totalEffect = new Balances({});

    for (const rule of this.rulesThatApply(evt, evt.tx)) {
      const ruleEffect = rule.apply(evt, evt.tx, this);
      if (ruleEffect instanceof Error) {
        return ruleEffect;
      }
      totalEffect = totalEffect.plus(ruleEffect);
    }
    return totalEffect
  }

  loadCaches() {
    this.cachedTxsByAddress = parseJsonWithBigInts(localStorage.getItem('__cachedTxsByAddress') || '{}');
    this.cachedReceiptsByHash = parseJsonWithBigInts(localStorage.getItem('__cachedReceiptsByHash') || '{}');
  }

  flushCaches() {
    localStorage.setItem('__cachedTxsByAddress', stringifyJsonWithBigInts(this.cachedTxsByAddress));
    localStorage.setItem('__cachedReceiptsByHash', stringifyJsonWithBigInts(this.cachedReceiptsByHash));
  }

  blowUpCaches() {
    this.cachedTxsByAddress = {};
    this.cachedReceiptsByHash = {};
    this.flushCaches();
  }
});


function App(props) {
  const [worldState, setWorldState] = useState(null);
  const [worldStateLoading, setWorldStateLoading] = useState(false);
  const [worldStateLoaded, setWorldStateLoaded] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [__, forceUpdate] = useReducer(x => x + 1, 0);

  const handleSave = (ws = worldState) => {
    const error = ws.anyError();
    if (error) {
      throw error;
    }

    localStorage.setItem('__serializedWorldState', JSON.stringify(ws.serialize()));
    forceUpdate();
  }

  /*
  const worldStateChange = (obj, attr) => (e) => {
    obj[attr] = e.target.value;
    handleSave();
  };
  */

  // Load the world state upon page load
  useEffect(() => {
    if (!worldStateLoading) {
      setWorldStateLoading(true);

      if (DANGEROUSLY_SEED_WORLD_STATE) {
        seedWorldState().then(ws => {
          setWorldState(ws);
          setWorldStateLoaded(true);
        });
      } else {
        const serializedState = JSON.parse(localStorage.getItem('__serializedWorldState') || null) || defaultWorldStateJson;
        setWorldState(WorldState.deserialize(serializedState));
        setWorldStateLoaded(true);
      }
    }

  }, [worldStateLoading, worldStateLoaded]);


  if (!worldStateLoaded) {
    return <div>Loading...</div>
  }

  return (
    <div className="container-scroller">
      <Header worldState={worldState} forceUpdate={forceUpdate} handleSave={handleSave} />
      <div className="container-fluid page-body-wrapper">
        <div className="main-panel">
          <div className="content-wrapper">
            <Suspense fallback={<Spinner/>}>
              <Routes>
                <Route path="/ledger" element={<LedgerBalances ledger={worldState.ledger} handleSave={handleSave} />} />
                <Route path="/ledger/:lp" element={<LedgerBalances ledger={worldState.ledger} handleSave={handleSave} />} />
                <Route path="/equity" element={<EquityDashboard ledger={worldState.ledger} handleSave={handleSave} />} />

                <Route path="/" element={<LedgerBalances ledger={worldState.ledger} handleSave={handleSave} />} />
              </Routes>
            </Suspense>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;

function decodeLog(logItem, abiArray) {
  return _decodeLog(logItem, makeAbiState(abiArray));
}

function _decodeLog(logItem, state) {
  const methodID = logItem.topics[0].slice(2);
  const method = state.methodIDs[methodID];
  if (!method) {
    return logItem;
  }

  const logData = logItem.data;
  let decodedParams = [];
  let dataIndex = 0;
  let topicsIndex = 1;

  let dataTypes = [];
  // eslint-disable-next-line array-callback-return
  method.inputs.map(function(input) {
    if (!input.indexed) {
      dataTypes.push(input.type);
    }
  });

  const decodedData = abiCoder.decodeParameters(
    dataTypes,
    logData.slice(2)
  );

  // Loop topic and data to get the params
  // eslint-disable-next-line array-callback-return
  method.inputs.map(function(param) {
    let decodedP = {
      name: param.name,
      type: param.type,
    };

    if (param.indexed) {
      decodedP.value = logItem.topics[topicsIndex];
      topicsIndex++;
    } else {
      decodedP.value = decodedData[dataIndex];
      dataIndex++;
    }

    if (param.type === "address") {
      decodedP.value = decodedP.value.toLowerCase();
      // 42 because len(0x) + 40
      if (decodedP.value.length > 42) {
        let toRemove = decodedP.value.length - 42;
        let temp = decodedP.value.split("");
        temp.splice(2, toRemove);
        decodedP.value = temp.join("");
      }
    }

    if (
      param.type === "uint256" ||
      param.type === "uint8" ||
      param.type === "int"
    ) {
      // ensure to remove leading 0x for hex numbers
      if (typeof decodedP.value === "string" && decodedP.value.startsWith("0x")) {
        decodedP.value = new BN(decodedP.value.slice(2), 16).toString(10);
      } else {
        decodedP.value = new BN(decodedP.value).toString(10);
      }

    }

    decodedParams.push(decodedP);
  });

  return {
    decoded: true,
    name: method.name,
    events: decodedParams,
    address: logItem.address,
  };
}

function _typeToString(input) {
  if (input.type === "tuple") {
    return "(" + input.components.map(_typeToString).join(",") + ")";
  }
  return input.type;
}

const makeAbiState = (abiArray) => {
  let state = {methodIDs: {}};

  if (Array.isArray(abiArray)) {
    // Iterate new abi to generate method id"s
    // eslint-disable-next-line array-callback-return
    abiArray.map(function(abi) {
      if (abi.name) {
        const signature = sha3(
          abi.name +
            "(" +
            abi.inputs
              .map(_typeToString)
              .join(",") +
            ")"
        );
        if (abi.type === "event") {
          state.methodIDs[signature.slice(2)] = abi;
        } else {
          state.methodIDs[signature.slice(2, 10)] = abi;
        }
      }
    });

    state.savedABIs = abiArray;
  } else {
    throw new Error("Expected ABI array, got " + typeof abiArray);
  }
  return state;
}

function decodeMethod(data, abiArray) {
  return _decodeMethod(data, makeAbiState(abiArray));
}

function _decodeMethod(data, state) {
  const methodID = data.slice(2, 10);
  const abiItem = state.methodIDs[methodID];
  if (abiItem) {
    let decoded = abiCoder.decodeParameters(abiItem.inputs, data.slice(10));

    let retData = {
      name: abiItem.name,
      params: [],
    };

    for (let i = 0; i < decoded.__length__; i++) {
      let param = decoded[i];
      let parsedParam = param;
      const isUint = abiItem.inputs[i].type.indexOf("uint") === 0;
      const isInt = abiItem.inputs[i].type.indexOf("int") === 0;
      const isAddress = abiItem.inputs[i].type.indexOf("address") === 0;

      if (isUint || isInt) {
        const isArray = Array.isArray(param);

        if (isArray) {
          parsedParam = param.map(val => new BN(val).toString());
        } else {
          parsedParam = new BN(param).toString();
        }
      }

      // Addresses returned by web3 are randomly cased so we need to standardize and lowercase all
      if (isAddress) {
        const isArray = Array.isArray(param);

        if (isArray) {
          parsedParam = param.map(_ => _.toLowerCase());
        } else {
          parsedParam = param.toLowerCase();
        }
      }

      retData.params.push({
        name: abiItem.inputs[i].name,
        value: parsedParam,
        type: abiItem.inputs[i].type,
      });
    }

    return retData;
  }
}
