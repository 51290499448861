import _ from 'lodash';
import {WorldState, Ledger, LedgerTransaction, LedgerEntry, Asset, LpTokenAsset, LedgerAccount, MapPriceFetcher} from './App';
import {CoinGeckoPriceFetcher} from './price-fetchers';
import {assert} from './utils';
import moment from 'moment';

export const allBlockchains = [
  'Ethereum', 'Fantom', 'Harmony', 'Polkadot', 'Avalanche', 'BSC', 'Polygon', 'Solana', 'Immutable X', 'Helium',
  'Arbitrum', 'Bitclout', 'Handshake', 'Ronin', 'Cosmos', 'Bitcoin', 'NEAR', 'Pocket Network', 'Thor Chain', 'Secret',
  'Kusama', 'Moonriver', 'Arweave', 'StarkWare', 'Terra', 'Tezos', 'Cardano', 'Algorand', 'Keep', 'Filecoin', 'Internet Computer',
  'Chainlink', 'Loopring', 'Stellar Lumens', 'Litecoin', 'DFK Chain',
];

const coinGeckoToken = (name, tokenName, ticker, blockchain) => {
  return new Asset({name, tokenName, blockchain, type: 'ERC20', priceFetcher: new CoinGeckoPriceFetcher({ticker})});
};

const zerodOutToken = (name, tokenName, ticker, blockchain) => {
  const priceFetcher = new MapPriceFetcher({underlyingAsset: {}});
  priceFetcher.map = (currency, price) => 0;
  return new Asset({name, tokenName, blockchain: blockchain, type: 'ERC20', priceFetcher});
}

const lpToken = (name, tokenName, constant, asset1, asset2, blockchain) => {
  return new LpTokenAsset({constant, asset1, asset2, name, blockchain});
};



const multipleToken = (asset, name, tokenName, type, multiple) => {
  const priceFetcher = new MapPriceFetcher({underlyingAsset: asset});
  priceFetcher.map = (currency, price) => price * multiple;
  return new Asset({name, tokenName, blockchain: asset.blockchain, type, priceFetcher});
}



export async function seedWorldState() {
  const assets = {
    blue: coinGeckoToken('Blue Pool', 'BLUE', 'usd-coin', 'Ethereum'),
    tick: coinGeckoToken('TickSpread', 'TICK', 'usd-coin', 'Ethereum'),
    usdc: coinGeckoToken('USD Coin', 'USDC', 'usd-coin', 'Ethereum'),
    gohm: coinGeckoToken('Governance OHM', 'gOHM', 'governance-ohm', 'Ethereum'),
    avax: coinGeckoToken('Avalance', 'AVAX', 'avalanche-2', 'Avalanche'),
    wonderland: coinGeckoToken('Wonderland', 'TIME', 'wonderland', 'Avalanche'),
    matic: coinGeckoToken('Polygon', 'MATIC', 'matic-network', 'Polygon'),
    one: coinGeckoToken('Harmony One', 'ONE', 'harmony', 'Harmony'),
    dpi: coinGeckoToken('DeFi Pulse Index', 'DPI', 'defipulse-index', 'Ethereum'),
    // Went out of CoinGecko on May 29 2023
    //dei: coinGeckoToken('Data Economy Index', 'DATA', 'data-economy-index', 'Ethereum'),
    mvi: coinGeckoToken('Metaverse Index', 'MVI', 'metaverse-index', 'Ethereum'),
    imx: coinGeckoToken('Immutable X', 'IMX', 'immutable-x', 'Immutable X'),
    fantom: coinGeckoToken('Fantom', 'FTM', 'fantom', 'Fantom'),
    handshake: coinGeckoToken('Handshake', 'HNS', 'handshake', 'Handshake'),
    axie: coinGeckoToken('Axie Infinity', 'AXS', 'axie-infinity', 'Ronin'),
    atom: coinGeckoToken('Cosmos', 'ATOM', 'cosmos', 'Cosmos'),
    osmosis: coinGeckoToken('Osmosis', 'OSMO', 'osmosis', 'Cosmos'),
    juno: coinGeckoToken('Juno', 'JUNO', 'juno-network', 'Cosmos'),
    bitcoin: coinGeckoToken('Bitcoin', 'BTC', 'bitcoin', 'Bitcoin'),
    ethereum: coinGeckoToken('Ethereum', 'ETH', 'ethereum', 'Ethereum'),

    // Went out of CoinGecko as of Mar 6 2024
    //fantohm: coinGeckoToken('FantOHM', 'FHM', 'fantohm', 'Fantom'),
    fantohm: zerodOutToken('FantOHM', 'FHM', 'fantohm', 'Fantom'),

    thetan: coinGeckoToken('Thetan Arena', 'THG', 'thetan-arena', 'BSC'),
    bnb: coinGeckoToken('Binance Coin', 'BNB', 'binancecoin', 'BSC'),
    aurory: coinGeckoToken('Aurory', 'AURY', 'aurory', 'Solana'),
    near: coinGeckoToken('Near Protocol', 'NEAR', 'near', 'NEAR'),
    octopus: coinGeckoToken('Octopus Network', 'OCT', 'octopus-network', 'NEAR'),
    aurora: coinGeckoToken('Aurora', 'AURORA', 'aurora-near', 'NEAR'),
    paras: coinGeckoToken('Paras', 'PARAS', 'paras', 'NEAR'),
    pokt: coinGeckoToken('Pocket Network', 'POKT', 'pocket-network', 'Pocket Network'),
    magic: coinGeckoToken('Magic', 'MAGIC', 'magic', 'Arbitrum'),
    looks: coinGeckoToken('Looks Rare', 'LOOKS', 'looksrare', 'Ethereum'),
    x2y2: coinGeckoToken('X2Y2', 'X2Y2', 'x2y2', 'Ethereum'),
    rune: coinGeckoToken('Thor Chain', 'RUNE', 'thorchain', 'Thor Chain'),
    helium: coinGeckoToken('Helium', 'HNT', 'helium', 'Helium'),
    secret: coinGeckoToken('Secret', 'SCRT', 'secret', 'Secret'),
    kusama: coinGeckoToken('Kusama', 'KSM', 'kusama', 'Kusama'),
    moonriver: coinGeckoToken('Moonriver', 'MOVR', 'moonriver', 'Moonriver'),
    aragon: coinGeckoToken('Aragon', 'ANT', 'aragon', 'Ethereum'),
    arweave: coinGeckoToken('Arweave', 'AR', 'arweave', 'Arweave'),
    dydx: coinGeckoToken('DyDx', 'DYDX', 'dydx', 'StarkWare'),
    jewel: coinGeckoToken('Defi Kingdoms Jewel', 'JEWEL', 'defi-kingdoms', 'Harmony'),
    luna: coinGeckoToken('Terra', 'LUNA', 'terra-luna', 'Terra'),
    cardano: coinGeckoToken('Cardano', 'ADA', 'cardano', 'Cardano'),
    tezos: coinGeckoToken('Tezos', 'XTZ', 'tezos', 'Tezos'),
    algorand: coinGeckoToken('Algorand', 'ALGO', 'algorand', 'Algorand'),
    keep: coinGeckoToken('Keep Network', 'KEEP', 'keep-network', 'Keep'),
    icp: coinGeckoToken('Internet Computer', 'ICP', 'internet-computer', 'Internet Computer'),
    chainlink: coinGeckoToken('Chainlink', 'LINK', 'chainlink', 'Chainlink'),
    loopring: coinGeckoToken('Loopring', 'LRC', 'loopring', 'Loopring'),
    litecoin: coinGeckoToken('Litecoin', 'LTC', 'litecoin', 'Litecoin'),
    stellar: coinGeckoToken('Stellar Lumens', 'XLM', 'stellar', 'Stellar Lumens'),
    fetch: coinGeckoToken('Fetch.ai', 'FET', 'fetch-ai', 'Ethereum'),
    augur: coinGeckoToken('Augur', 'REP', 'augur', 'Ethereum'),
    alchemy: coinGeckoToken('Alchemy Pay', 'ACH', 'alchemy-pay', 'Ethereum'),
    doge: coinGeckoToken('Dogecoin', 'DOGE', 'dogecoin', 'Ethereum'),
    rally: coinGeckoToken('Rally', 'RLY', 'rally-2', 'Ethereum'),
    livepeer: coinGeckoToken('Livepeer', 'LPT', 'livepeer', 'Ethereum'),
    solana: coinGeckoToken('Solana', 'SOL', 'solana', 'Solana'),
    ninja: coinGeckoToken('Ninja Protocol', 'NINJA', 'ninja-protocol', 'Solana'),
    polkadot: coinGeckoToken('Polkadot', 'DOT', 'polkadot', 'Polkadot'),
    filecoin: coinGeckoToken('Filecoin', 'FIL', 'filecoin', 'Filecoin'),
    truefi: coinGeckoToken('TrueFi', 'TRUE', 'truefi', 'Ethereum'),
    tomb: coinGeckoToken('Tomb', 'TOMB', 'tomb', 'Fantom'),
    tshares: coinGeckoToken('Tomb Shares', 'TSHARES', 'tomb-shares', 'Fantom'),
    fwb: coinGeckoToken('Friends With Benefits', 'FWB', 'friends-with-benefits-pro', 'Ethereum'),
    ape: coinGeckoToken('ApeCoin', 'APE', 'apecoin', 'Ethereum'),
    convex: coinGeckoToken('Convex Finance', 'CVX', 'convex-finance', 'Ethereum'),
  };

  const ethMultipleToken = (name, symbol, type, multiple) => multipleToken(assets.ethereum, name, symbol, type, multiple);

  const nfts = {
    loot: ethMultipleToken('Loot', 'LOOT', 'ERC721', 2.5),
    deadringer: ethMultipleToken('DeadRinger', 'DEADRINGER', 'ERC721', 0.05),
    jims: ethMultipleToken('The Jims', 'JIMS', 'ERC721', 0.4550),
    treeverse: ethMultipleToken('Treeverse', 'TREEVERSE', 'ERC721', 1.65),
    timelessCharacters: ethMultipleToken('Timeless Characters', 'TIMELESS', 'ERC721', 0.8),
    chainrunners: ethMultipleToken('Chain Runners', 'RUNNERS', 'ERC721', 0.845),
    stonercats: ethMultipleToken('Stoner Cats', 'STONERS', 'ERC721', 0.29),
    flamingoFlutter: ethMultipleToken('Flamingo Flutter', 'FLUTTER', 'ERC721', 0.36),
    philosophicalFoxes: ethMultipleToken('Philosophical Foxes', 'FOXES', 'ERC721', 0.7),
    dfkHeroes: multipleToken(assets.jewel, 'DFK Heroes', 'DFK-HERO', 'ERC721', 30),
    voxelglyph: ethMultipleToken('Voxelglyph', 'VOXELGLYPH', 'ERC721', 3),
  }

  const multipleTokens = {
    fingerprints: ethMultipleToken('FingerprintsDAO', 'PRINTS', 'ERC20', 0.002),
    raw: ethMultipleToken('RawDAO', 'RAW', 'ERC20', 0.0022),
    flames: ethMultipleToken('The Community Twin', 'FLAMES', 'ERC20', 0.00011),

    bitclout: ethMultipleToken('Bitclout', 'DESO', 'ERC20', 0),

    xJewel: multipleToken(assets.jewel, 'xJewel', 'X-JEWEL', 'ERC20', 1.72),
    crystal: multipleToken(assets.jewel, 'Crystal', 'CRYSTAL', 'ERC20', 4.21),
    xCrystal: multipleToken(assets.jewel, 'xCrystal', 'X-CRYSTAL', 'ERC20', 4.21 * 1.018),

  }

  // LP constant = q1 * q2 /LPTokens^2
  const lpTokenAssets = {
    jewelOne: lpToken('Jewel / One LP', 'JEWEL-ONE-LP', 1.2426907, assets.jewel, assets.one, 'Harmony'),
    jewelMatic: lpToken('Jewel / Matic LP', 'JEWEL-MATIC-LP', 1.05093009, assets.jewel, assets.matic, 'Harmony'),
    jewelAvax: lpToken('Jewel / Avax LP', 'JEWEL-AVAX-LP', 1.05504396, assets.jewel, assets.avax, 'Harmony'),

    jewelXJewel: lpToken('Jewel / xJewel LP Crystalvale', 'CRYSTAL-LP', 1.00667296336293, assets.jewel, multipleTokens.xJewel, 'DFK Chain'),
    crystalUsdc: lpToken('Crystal / USDC LP', 'CRYSTAL-USDC-LP', 1.03740367, multipleTokens.crystal, assets.usdc, 'DFK Chain'),
    crystalAvax: lpToken('Crystal / Avax LP', 'CRYSTAL-AVAX-LP', 1.02358483389, multipleTokens.crystal, assets.avax, 'DFK Chain'),
    crystalJewel: lpToken('Crystal / Jewel LP', 'CRYSTAL-JEWEL-LP', 1.03079324752, multipleTokens.crystal, assets.jewel, 'DFK Chain'),

    ninjaSol: lpToken('Ninja / SOL LP', 'NINJA-SOL-LP', 0.00353788166, assets.ninja, assets.solana, 'Solana'),
    tombFtm: lpToken('Tomb / FTOM LP', 'TOMB-FTM-LP', 2.16486360652, assets.tomb, assets.fantom, 'Fantom'),
    junoOsmo: lpToken('Juno / Osmosis LP', 'JUNO-OSMO-LP', 2.82323892074, assets.osmosis, assets.juno, 'Cosmos'),
    atomOsmo: lpToken('Atom / Osmosis LP', 'ATOM-OSMO-LP', 0.00139903216, assets.osmosis, assets.atom, 'Cosmos'),
  };

  const kakashi = new LedgerAccount({name: 'Kakashi', type: 'Equity', uniqueKey: 'kakashi'});
  const maitoGai = new LedgerAccount({name: 'Maito Gai', type: 'Equity', uniqueKey: 'maitoGai'});
  const hashirama = new LedgerAccount({name: 'Hashirama', type: 'Equity', uniqueKey: 'hashirama'});
  const hinata = new LedgerAccount({name: 'Hinata', type: 'Equity', uniqueKey: 'hinata'});
  const youngMinato = new LedgerAccount({name: 'Young Minato', type: 'Equity', uniqueKey: 'youngMinato'});
  const pikachu = new LedgerAccount({name: 'Pikachu', type: 'Equity', uniqueKey: 'pikachu'});
  const naruto = new LedgerAccount({name: 'Naruto', type: 'Equity', uniqueKey: 'naruto'});
  const alice = new LedgerAccount({name: 'Alice', type: 'Equity', uniqueKey: 'alice'});

  const equityAccounts = [kakashi, maitoGai, hashirama, hinata, youngMinato, pikachu, naruto, alice];
  assert(() => _.isEmpty(_.intersection(_.keys(assets), _.keys(lpTokenAssets))))
  const allAssets = _.extend({}, assets, nfts, multipleTokens, lpTokenAssets);

  const otherAccounts = {
    usd: new LedgerAccount({name: 'Main USD Account', asset: assets.usdc, uniqueKey: 'usd'}),
    theSource: new LedgerAccount({name: 'Source of ETH', asset: assets.ethereum, uniqueKey: 'theSource'}),
  };

  let accountKeysByName = {};
  const investmentAccounts = _.map(allAssets, (asset, mainAccountName) => {
    const account = new LedgerAccount({name: asset.name, asset, uniqueKey: mainAccountName});
    accountKeysByName[mainAccountName] = account.uniqueKey;
    return account;
  }).concat(_.values(otherAccounts));

  _.extend(accountKeysByName, _.mapValues(otherAccounts, a => a.uniqueKey));

  const ledger = new Ledger({accounts: equityAccounts.concat(investmentAccounts)});

  const initialKakashiTokens = 1000;
  const initialInvestments = [
    /* Ledger 1 */
    {account: 'gohm', amount: 0.7579},
    {account: 'dpi', amount: 24.13},
    //{account: 'dei', amount: 40.6363},
    {account: 'mvi', amount: 24.57},
    {account: 'thetan', amount: 426},
    {account: 'octopus', amount: 256.5976},
    {account: 'fingerprints', amount: 6500},
    {account: 'raw', amount: 1650},
    {account: 'flames', amount: 3000},
    {account: 'pokt', amount: 3300},
    {account: 'bitclout', amount: 3.18},
    {account: 'handshake', amount: 6893.27},

    {account: 'xJewel', amount: 1100},
    {account: 'jewel', amount: 5002.8286},
    {account: 'jewel', amount: 304},

    {account: 'imx', amount: 505.9497},
    {account: 'fantom', amount: 3249.1237},
    {account: 'fantom', amount: 1546.7541},
    {account: 'bitcoin', amount: 0.0789},
    {account: 'matic', amount: 6752.8034},
    {account: 'ethereum', amount: 5.37}, // One eth
    {account: 'ethereum', amount: 1.25}, // Stringer Ledger
    {account: 'avax', amount: 39.1966},
    {account: 'bnb', amount: 7.6693},
    {account: 'one', amount: 10578.0182},
    {account: 'near', amount: 817.67},
    {account: 'atom', amount: 162.9993},
    {account: 'atom', amount: 624.9937}, // staked
    {account: 'atom', amount: 5.5},

    /* LP tokens */
    {account: 'jewelOne', amount: 7018.5781},
    {account: 'ninjaSol', amount: 781.11998, where: 'phantom'},
    {account: 'tombFtm', amount: 150.3134},

    /* Staked / Earning rewards */
    {account: 'magic', amount: 1480}, // staked
    {account: 'axie', amount: 31.47}, // staked
    {account: 'aurory', amount: 421.3030, where: 'phantom'}, // staked
    {account: 'paras', amount: 1264}, // staked
    {account: 'wonderland', amount: 2.2458}, // very staked
    {account: 'fantohm', amount: 13.6414}, // very staked

    /* Rewards accruals */
    {account: 'tshares', amount: 0.0220},

    /* Near Wallet */
    {account: 'octopus', amount: 525.85313},
    {account: 'aurora', amount: 120.51577},

    /* Binance */
    {account: 'fantom', amount: 5667.26291713, where: 'binance'},
    {account: 'avax', amount: 85.21896074, where: 'binance'},
    {account: 'bnb', amount: 15.00980661, where: 'binance'},
    {account: 'rune', amount: 1163.01259279, where: 'binance'},
    {account: 'helium', amount: 150, where: 'binance'},
    {account: 'secret', amount: 685.3, where: 'binance'},
    {account: 'kusama', amount: 13.486, where: 'binance'},
    {account: 'aragon', amount: 331.4, where: 'binance'},
    {account: 'arweave', amount: 36.95, where: 'binance'},
    {account: 'moonriver', amount: 20.79800000, where: 'binance'},
    {account: 'dydx', amount: 160, where: 'binance'},

    /* Coinbase*/
    {account: 'bitcoin', amount: 1.7845, where: 'coinbase'},
    {account: 'ethereum', amount: 14.7488, where: 'coinbase'},
    {account: 'ethereum', amount: -1.7653, where: 'coinbase'},
    {account: 'luna', amount: 490.4241, where: 'coinbase'},
    {account: 'solana', amount: 135.5236, where: 'coinbase'},
    {account: 'tezos', amount: 1908.1656, where: 'coinbase'},
    {account: 'cardano', amount: 5600.1346, where: 'coinbase'},
    {account: 'polkadot', amount: 293.8057, where: 'coinbase'},
    {account: 'algorand', amount: 4877.05, where: 'coinbase'},
    {account: 'icp', amount: 161.049, where: 'coinbase'},
    {account: 'keep', amount: 7299.9667, where: 'coinbase'},
    {account: 'filecoin', amount: 148.451, where: 'coinbase'},
    {account: 'chainlink', amount: 90.4084, where: 'coinbase'},
    {account: 'loopring', amount: 1460, where: 'coinbase'},
    {account: 'stellar', amount: 3987.57, where: 'coinbase'},
    {account: 'litecoin', amount: 7.406, where: 'coinbase'},
    {account: 'fetch', amount: 1953.71, where: 'coinbase'},
    {account: 'augur', amount: 36.2136, where: 'coinbase'},
    {account: 'truefi', amount: 2356.226, where: 'coinbase'},
    {account: 'alchemy', amount: 9158.96, where: 'coinbase'},
    {account: 'doge', amount: 2285.35, where: 'coinbase'},
    {account: 'rally', amount: 1264.38, where: 'coinbase'},
    {account: 'livepeer', amount: 6.01, where: 'coinbase'},

    /* NFTs */
    {account: 'jims', amount: 2},
    {account: 'chainrunners', amount: 2},
    {account: 'timelessCharacters', amount: 1},
    {account: 'treeverse', amount: 1},
    {account: 'stonercats', amount: 1},
    {account: 'flamingoFlutter', amount: 2},
  ];


  assert(() => !ledger.anyError());
  assert(() => ledger.serialize() instanceof Object);

  initialInvestments.forEach(({account: accountName, amount, date, where, rest}) => {
    console.log(accountName)

    assert(() => accountName in accountKeysByName);

    ledger.addTransaction(new LedgerTransaction({
      entries: [new LedgerEntry({
        accountUniqueKey: accountKeysByName[accountName],
        amount
      }), new LedgerEntry({
        accountUniqueKey: kakashi.uniqueKey,
        amount: initialKakashiTokens / initialInvestments.length,
      })],
      accrualTime: new Date('9/24/2021'),
    }));
  });

  const LPs = [
    {account: maitoGai, eth: 1.3827, accrualTime: new Date('01/19/2022'),  txId: '0x6e3917029b73dbb226ba4d9e0ef19533fad6152726d746d5e9a20e963ddb70a7', kakashiTokens: 228.565},
    {account: maitoGai, eth: 18.8177917, accrualTime: new Date('01/19/2022'), txId: '0x067d293cc011ec815fa5f888f6ea3bf9f803a0f15fa60fedb0f738f370458df6'},
    {account: maitoGai, eth: 7.99, accrualTime: new Date('01/20/2022'), txId: '0x8a7b22be18803840b12ccf8c53a8628deaf8f09c412e1394ac2ff75e8dc58bce'},
    {account: maitoGai, eth: 17.1087848, accrualTime: new Date('01/20/2022'), txId: '0xfac08edaee65deadce90a866fb02aa0a6c55dac07073a24d1d99e1a3cf5ea1fb'},

    {account: hashirama, eth: 4, accrualTime: new Date('01/21/2022'), txId: '0x72884e83e7071ecf53d3748262deddf40b249a6bf548a7fa1364e6016654cf57', kakashiTokens: 54.446},
    {account: hashirama, eth: 6, accrualTime: new Date('01/21/2022'), txId: null}, // Coinbase tx

    {account: hinata, eth: 10, accrualTime: new Date('01/25/2022 3:26:00'), txId: '0x47f9ea1a0ccb80007f7efeb39ee11500700b4c42a6f167bdac848fa39216527c', kakashiTokens: 55.403},

    {account: youngMinato, eth: 1, accrualTime: new Date('01/25/2022 23:52:24'), txId: '0xaa50b22cb2c88fdd3a814b467290e22afa1457044de8dcea20fe9a4c81bd391e', kakashiTokens: 5.54},
    {account: pikachu, eth: 0.8, accrualTime: new Date('02/22/2022 23:52:24'), txId: '0x3fa70880ae170b28ec7169d819b849735f107ea754c054b7ebf2134c38b6a54c', kakashiTokens: 5.091},

    {account: naruto, eth: 12.6, accrualTime: new Date('02/24/2022 23:52:24'), txId: '0x6d8e6118459c44788512cc158df096200ae22b3c53aefe0bc3b514e16610a402', kakashiTokens: 80.366},

    {account: alice, eth: 0.098, accrualTime: new Date('04/01/2022 23:52:24'), txId: '0xa303ed41b46da0a591fb344c6cb519ac9daea9e722b2217d1ab2dd89f1b6db34', kakashiTokens: 60.009},
    {account: alice, eth: 9.48353, accrualTime: new Date('04/01/2022 23:52:24'), txId: '0x33c49851df5a8d6d47aace30b3a005fa471f8ac23a75a164e368fe4855efba42'},
  ];

  const ltx = (accrualTime, shorthandEntries) => {
    return new LedgerTransaction({
      accrualTime,
      entries: shorthandEntries.map(([accountName, amount]) =>
        new LedgerEntry({accountUniqueKey: accountKeysByName[accountName], amount})
      ),
    });
  };

  // We accrue unclaimed rewards 1 day into the past so we don't see a big jump in value between yesterday and today
  const unclaimedRewards = (accountName, amount) => ltx(moment().subtract(1, 'days').toDate(), [[accountName, amount]]);
  const gasFee = (accrualTime, accountName, amount) => ltx(accrualTime, [[accountName, -amount]]);

  const trades = [
    // Coinbase post Jonjon investment
    ltx(new Date('01/22/2022'), [['theSource', -6], ['usd', 3 * 4926.59]]),
    ltx(new Date('01/22/2022'), [['usd', -4926.59], ['luna', 69.4185]]),
    ltx(new Date('01/22/2022'), [['usd', -4926.59], ['ethereum', 1.7653]]),
    ltx(new Date('01/22/2022'), [['usd', -4926.59], ['atom', 135.4737]]),

    // DFK
    ltx(new Date('01/21/2022 02:36:58'), [
      ['theSource', -15], ['jewelOne', 2703.1850], ['jewelMatic', 1156.2964], ['jewelAvax', 182.2412],
      ['bitcoin', 0.1401], ['one', 8340.1993]
    ]),

    ltx(new Date('01/21/2022 03:40:54'), [['theSource', -19.3], ['pokt', 29393.76 - 3300]]),
    ltx(new Date('01/21/2022 09:16:15'), [['theSource', -3], ['looks', 1838.174035000513910562]]),
    ltx(new Date('01/22/2022 05:07:30'), [['theSource', -6], ['near', 1224.05102]]),
    ltx(new Date('01/22/2022 05:07:30'), [['theSource', -0.05], ['deadringer', 1]]),
    ltx(new Date('02/02/2022 02:51:27'), [['theSource', -0.934046209782754769], ['looks', 500]]),
    ltx(new Date('02/02/2022 02:03:17'), [['theSource', -2.558011666053586416], ['fwb', 150]]),
    ltx(new Date('02/02/2022 02:55:11'), [['theSource', -1.87415057933030191], ['looks', 100]]),

    ltx(new Date('02/01/2022 02:39:33'), [['ethereum', -0.05], ['deadringer', 1]]),

    ltx(new Date('02/02/2022 12:06:06'), [['jewelOne', -3491.25], ['jewel', 593.398838565854881119], ['one', 23093.603679015898653207]]),
    ltx(new Date('02/02/2022 12:06:06'), [['one', -15000], ['ethereum', 1.019695079155978339]]),
    ltx(new Date('02/02/2022 12:06:06'), [['jewel', -849.54311], ['bitcoin', 0.16064]]),

    ltx(new Date('02/05/2022 10:50:00'), [['theSource', -0.4], ['philosophicalFoxes', 1]]),
    ltx(new Date('02/07/2022 10:50:00'), [['fantom', -1289.5516229], ['moonriver', 27.65079949]]),

    ltx(new Date('02/09/2022'), [['jewel', 50]]),
    ltx(new Date('02/09/2022'), [['jewel', -50], ['bitcoin', 0.01033494]]),

    ltx(new Date('02/15/2022'), [['theSource', 2.52], ['loot', 1]]),

    ltx(new Date('02/02/2022'), [['magic', 16.45]]), // rewards accrual

    gasFee(new Date('02/02/2022 02:55:11'), 'theSource', 0.14),

    ltx(new Date('02/18/2022'), [['theSource', -5.5], ['avax', 1.38], ['blue', 15000]]),
    ltx(new Date('02/18/2022'), [['theSource', -1], ['x2y2', 1902]]),

    // Rewards accruals
    ltx(new Date('02/21/2022'), [['looks', 3814 - 1838 - 500 - 100]]),
    ltx(new Date('02/21/2022'), [['ethereum', 0.9557]]),
    ltx(new Date('02/21/2022'), [['jewel', 278.6938]]), // Jewel Matic
    ltx(new Date('02/21/2022'), [['jewel', 255.6367]]), // Jewel Avax
    ltx(new Date('02/21/2022'), [['jewel', 221.4317]]), // Jewel One Kakashi
    ltx(new Date('02/21/2022'), [['jewel', 175.6701]]), // Jewel One Ledger 1
    ltx(new Date('02/21/2022'), [['jewel', 64.6399]]), // xJewel
    ltx(new Date('02/21/2022'), [['tshares', 0.0272]]),
    ltx(new Date('02/21/2022'), [['magic', 19.06]]),
    ltx(new Date('02/21/2022'), [['axie', 1.9042]]),
    ltx(new Date('02/21/2022'), [['aurory', 33.1385]]),
    ltx(new Date('02/21/2022'), [['pokt', 32823.43 - 29393]]),

    // Julia joins fund on 02/22

    ltx(new Date('02/24/2022 06:00:00'), [['luna', -86.8310], ['usdc', 5000]]),
    ltx(new Date('02/24/2022 06:00:00'), [['jewel', -394.831081139814882078], ['usdc', 1919.853587]]),

    // Marcelo joins fund on 02/24

    ltx(new Date('02/25/2022'), [['theSource', -5], ['bitcoin', 0.3440277]]),

    ltx(new Date('02/25/2022'), [['theSource', -6], ['usdc', 16241.397255]]),
    ltx(new Date('02/25/2022'), [['usdc', -20000], ['tick', 20000]]),

    ltx(new Date('02/25/2022'), [['luna', -21.46383344], ['ape', 148.72999463]]),

    ltx(new Date('03/31/2022'), [['jewelAvax', -170], ['jewel', 241.72 + 527.425], ['avax', 58.7292]]),
    ltx(new Date('03/31/2022'), [['avax', -58.7292], ['bitcoin', 0.12283436]]),
    ltx(new Date('03/31/2022'), [['xJewel', -71.04], ['dfkHeroes', 2]]),
    ltx(new Date('03/31/2022'), [['xJewel', -400], ['jewelXJewel', 523.614]]),

    ltx(new Date('03/31/2022'), [['jewelOne', -1500], ['jewel', 466.9739 + 200], ['one', 12802.4]]),
    ltx(new Date('03/31/2022'), [['one', -5000], ['bitcoin', 0.0186]]),
    ltx(new Date('03/31/2022'), [['one', -5000], ['jewel', 77.9]]),
    ltx(new Date('03/31/2022'), [['jewel', -275], ['xJewel', 160.126949722799853031]]),
    ltx(new Date('03/31/2022'), [['xJewel', -160.126949722799853031], ['jewel', -274], ['jewelXJewel', 208.87]]),
    ltx(new Date('03/31/2022'), [['jewel', -10], ['xCrystal', 3.7]]),

    ltx(new Date('03/31/2022'), [['atom', -319], ['junoOsmo', 189.195712 ]]),
    ltx(new Date('03/31/2022'), [['bitcoin', -0.053744 - 0.053893], ['atom', 82.939793 + 83.756015]]),
    ltx(new Date('03/31/2022'), [['atom', -82.939793 - 83.756015], ['atomOsmo', 4120.972294]]),

    ltx(new Date('03/31/2022'), [['pokt', 36641.48 - 32824.19]]), // rewards
    ltx(new Date('03/31/2022'), [['magic', 65.85]]), // rewards
    ltx(new Date('03/31/2022'), [['ethereum', 1.21]]), // looks rewards
    ltx(new Date('03/31/2022'), [['looks', 4145 - 3814]]), // looks rewards

    // Dani joins fund on 04/01

    ltx(new Date('04/02/2022'), [['jewel', 771.390]]), // rewards
    ltx(new Date('04/02/2022'), [['xCrystal', 4.1307 + 78.9675]]), // xCrystal airdrop

    ltx(new Date('04/02/2022'), [['jewelMatic', -156.2], ['jewel', 63.9824], ['matic', 405.955]]),
    ltx(new Date('04/02/2022'), [['matic', -405.955], ['jewel', 63.7808]]),
    ltx(new Date('04/02/2022'), [['jewel', -444], ['crystalJewel', 42.43], ['crystalAvax', 12.65], ['crystalUsdc', 122.1]]),

    ltx(new Date('04/03/2022'), [['convex', 469.833267432718169243], ['theSource', -5]]),

    ltx(new Date('08/01/2023'), [['ethereum', 0.90486], ['fingerprints', -1500]]),

    ltx(new Date('08/01/2023'), [['voxelglyph', 1], ['fingerprints', -5000]]),

    /* Zeroing NFT values. We still have those, but I'm considering most of them aren't worth anything
     * anymore */

    ltx(new Date('12/31/2022'), [['raw', -1650]]),
    ltx(new Date('12/31/2022'), [['blue', -15000]]),
    ltx(new Date('12/31/2022'), [['loot', -1]]),
    ltx(new Date('12/31/2022'), [['treeverse', -1]]),
    ltx(new Date('12/31/2022'), [['chainrunners', -2]]),
    ltx(new Date('12/31/2022'), [['jims', -2]]),
    ltx(new Date('12/31/2022'), [['tick', -20000]]),
    ltx(new Date('12/31/2022'), [['flames', -3000]]),
    ltx(new Date('12/31/2022'), [['philosophicalFoxes', -1]]),
    ltx(new Date('12/31/2022'), [['timelessCharacters', -1]]),
    ltx(new Date('12/31/2022'), [['flamingoFlutter', -2]]),
    ltx(new Date('12/31/2022'), [['stonercats', -1]]),
    ltx(new Date('12/31/2022'), [['bitcoin', -0.53]]),
    ltx(new Date('12/31/2022'), [['theSource', -12.258981914833365]]),

    /* Zeroing some tokens */
    ltx(new Date('03/06/2024'), [['fantohm', -13.6414]]),

    unclaimedRewards('looks', 3814 - 3814), // Looks
    unclaimedRewards('ethereum', 0.9557 - 0.9557), // Looks
    unclaimedRewards('jewel', 278.6938 - 278.6938), // Jewel Matic
    unclaimedRewards('jewel', 221.4317 - 221.4317), // Jewel One Kakashi
    unclaimedRewards('jewel', 175.6701 - 175.6701), // Jewel One Ledger 1
    unclaimedRewards('tshares', 0.0272 - 0.0272), // Tomb LP
    unclaimedRewards('magic', 19.06 - 19.06), // Magic Atlas mine
    unclaimedRewards('axie', 1.9042 - 1.9042), // Axie staking
    unclaimedRewards('aurory', 33.1385 - 33.1385), // Aurory
    unclaimedRewards('pokt', 32823.45 - 32823.45), // POKTPool
  ];

  _.forEach(trades, ltx => ledger.addTransaction(ltx));

  const mgtFee = 0.02;
  /*
  for (let {account: equityAccount, eth: investedEth, accrualTime} of _.sortBy(LPs, 'accrualTime')) {
    const dailyEthValues = await ledger.dailyInvestmentValues(360, {name: 'eth'});
    const eth = investedEth * (1 - mgtFee);

    // newShares / (newShares + outstanding) = eth / (eth + ethValueOfFundBeforeInvestment)
    const ethValueOfFundBeforeInvestment = _.find(dailyEthValues, {name: moment(accrualTime).format('l')}).value;
    const pctOwnership = eth / (eth + ethValueOfFundBeforeInvestment);
    const newShares = pctOwnership / (1 - pctOwnership) * ledger.totalEquityTokens();

    ledger.addTransaction(new LedgerTransaction({
      accrualTime,
      entries: [
        new LedgerEntry({
          accountUniqueKey: equityAccount.uniqueKey,
          amount: newShares,
        }),
        new LedgerEntry({
          accountUniqueKey: otherAccounts.theSource.uniqueKey,
          amount: eth})
      ],
    }))
    */

  for (let {account: equityAccount, eth: investedEth, accrualTime, kakashiTokens} of _.sortBy(LPs, 'accrualTime')) {
    const eth = investedEth * (1 - mgtFee);
    ledger.addTransaction(new LedgerTransaction({
      accrualTime,
      entries: [
        new LedgerEntry({
          accountUniqueKey: equityAccount.uniqueKey,
          amount: kakashiTokens || 0,
        }),
        new LedgerEntry({
          accountUniqueKey: otherAccounts.theSource.uniqueKey,
          amount: eth})
      ],
    }))
  }

  return new WorldState({ledger});
}
